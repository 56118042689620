/**
 *  FooterFacebookRight Main
 */
import React, {Component} from 'react';

class FooterFacebookRight extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.refreshFacebookPage = this.refreshFacebookPage.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.refreshFacebookPage();

    }

    refreshFacebookPage() {
        window.FB.init({
            appId: '770753807046943',
            status: true,
            xfbml: true,
            version: 'v2.7'
        });
        window.FB.XFBML.parse();
    }

    render() {

        return (
            <div>

                <div className="fb-page"
                     data-href="https://www.facebook.com/afoimichail"
                     data-width="380"
                     data-hide-cover="false"
                     data-show-facepile="false"/>
            </div>
        )
    }
};

export default FooterFacebookRight;


