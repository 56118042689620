
/**
 *  PropertyPage Main Page
 */
import React , {Component} from 'react';
import SideFilter from '../../widgets/propertyfilter/SideFilter';
import SocialFilter from '../../widgets/propertyfilter/SocialFilter';
import { Link } from 'react-router-dom';
import { Row, Col,Container } from 'reactstrap';
import AllProduct from '../../api/product';
import ProductList from '../../widgets/ProductList';
import {getFilterProductsdata} from '../../services';
import { connect } from 'react-redux';
import TopFilter from '../../widgets/propertyfilter/TopFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import SocialFacebookPage from "../../widgets/propertyfilter/SocialFacebookPage";
import {Helmet} from "react-helmet";

class PropertyPage extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
          limit: 5,
          hasMoreProduct: true,
          getproduct:AllProduct
        }
    }
    componentWillMount(){
        if (this.state.limit < this.state.getproduct.length) {
            setTimeout(() => {
                this.setState({
                    limit: this.state.limit + 5
                });
            }, 2500);
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    componentDidUpdate(prevProps)
    {
        if (this.state.limit < prevProps.products.length) {
            setTimeout(() => {
                this.setState({
                    limit: this.state.limit + 5
                });
            }, 2500);
        }
    }
    fetchProduct = () => {
        if (this.state.limit >= this.props.products.length) {
            this.setState({ hasMoreProduct: false });
            return;
        }
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 5
            });
        }, 2500);
    }
    render() {
        let {products} = this.props;
        let layoutstyle=localStorage.getItem('setLayoutStyle')

        if(layoutstyle == null)
        {
            layoutstyle=localStorage.setItem('setLayoutStyle','col-sm-6 col-md-4')
        }

     return (
            <div className="site-content">
                <div className="inner-intro">
                <Container>
                    <Row className="intro-title align-items-center">
                        <Col md={6} className="text-left">
                            <div className="intro-title-inner">
                            <h1>Διαθέσιμα Ακίνητα</h1>

                                <Helmet>
                                    <title>Διαθέσιμα Ακίνητα «Αφοί Μιχαήλ» </title>
                                    <meta name="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα.
                                      Πώληση Ακινήτων, Διαμερισμάτων, Ενοικίαση Ακινήτων στη περιοχής της λαμίας φθιώτιδας
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                                    <meta itemProp="name" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>

                                    <meta itemProp="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα.
                                      Πώληση Ακινήτων, Διαμερισμάτων, Ενοικίαση Ακινήτων στη περιοχής της λαμίας φθιώτιδας
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                                    <meta itemProp="image" content="https://afoimichail.com//mainSitePage.jpg"/>

                                    <meta property="og:url"
                                          content={`https://afoimichail.com/${window.location.hash}`}/>
                                    <meta property="og:type" content="website"/>
                                    <meta property="og:title" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>
                                    <meta property="og:description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα.
                                      Πώληση Ακινήτων, Διαμερισμάτων, Ενοικίαση Ακινήτων στη περιοχής της λαμίας φθιώτιδας
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>
                                    <meta property="og:image"
                                          content="https://afoimichail.com/mainSitePage.jpg"/>

                                </Helmet>

                            </div>
                        </Col>
                        <Col md={6}  className="text-right">
                            <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                            <li className="home">
                                <span>
                                <Link className="bread-link bread-home" to="/">Αρχική</Link>
                                </span>
                            </li>
                            <li><span>Διαθέσιμα Ακίνητα</span></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                </div>
                <div className="content-wrapper section-pt mb-3 mb-md-5">
                  <Container>
                        <Row>
                        <div className="sidebar col-xl-4 col-lg-4 desktop">
                                <div className="shop-sidebar-widgets">

                                    <SideFilter />

                                    <SocialFacebookPage/>

                                    <SocialFilter />

                                </div>
                            </div>
                            <div className="content col-xl-8 col-lg-8">
                                <div className="products-header">
                                    <div className="right-banner">
                                        <img alt="Διαθέσιμα Ακίνητα" src={require(`../../assets/real-estate/properties/propert-banner.jpg`)}  className="img-fluid" />
                                    </div>
                                    <div className="loop-header">
                                        <div className="loop-header-tools">
                                            <div className="loop-header-tools-wrapper">
                                                <TopFilter productlength={products.length}  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {products.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={this.state.limit}
                                        next={this.fetchProduct}
                                        hasMore={this.state.hasMoreProduct}
                                        loader={<div className="lazyload-img"/>}
                                    >
                                        <Row className="products products-loop grid ciyashop-products-shortcode pgs-product-list">
                                            {products.slice(0,this.state.limit).map((product, index) =>
                                                        <ProductList product={product} key={index} layoutstyle={layoutstyle} />
                                                )
                                            }
                                        </Row>
                                    </InfiniteScroll>
                                :
                                      <Row className="products products-loop grid ciyashop-products-shortcode">
                                        <div className="col-sm-12 text-center  mt-5" >
                                            <img src={require(`../../assets/images/empty-search.jpg`)} className="img-fluid mb-4" alt="" />
                                            <h3>Συγνώμη! Δεν βρέθηκαν ακίνητα που πληρούν τα κριτήρια αναζήτησης!    </h3>
                                            <p>Παρακαλώ δοκιμάστε διαφορετικά κριτήρια.</p>
                                            <a href={`/properties`} className="btn btn-solid">Συνέχεια Αναζήτησης Ακινήτου</a>
                                        </div>
                                      </Row>
                                }
                            </div>

                        </Row>
                  </Container>
                </div>
        </div>
      )
    }
}
const mapDispatchToProps  = (state) => ({
    products: getFilterProductsdata(state.data, state.filters)
});
export default connect(
    mapDispatchToProps , {}
)(PropertyPage)
