/**
 *  PropertyMainPage
 */
import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";
import TopProperties from "../../widgets/TopProperties";


class PropertyMainPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        return (

            <div id="content" className="site-content" tabIndex={-1}>
                <div className="content-wrapper content-wrapper-vc-enabled">
                    <Container id="services">

                        <Row className="mb-0 mb-sm-3 mb-lg-5">
                            <Col sm={12}>
                                <Row mb={3} className="justify-content-center">
                                    <Col sm={10} lg={6} className="text-center">
                                        <div className="section-title">
                                            <h1 className="title"><a href="https://afoimichail.com/#/properties/" rel="noopener noreferrer" >Ακίνητα</a></h1>
                                            <p>Επιλέξτε από τα διαθέσιμα ακίνητα και επικοινωνήστε μαζί μας
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <TopProperties/>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>
        )
    }
};
export default PropertyMainPage;


