
export const searchValue = (search) => ({
    type: "RESULT_SEARCH_VALUE",
    search
});

export const categoryValue = (category) => ({
    type: "GET_CATEGORY_VALUE",
    category
});

export const categoryFieldValue = (categoryField) => ({
    type: "GET_CATEGORY_FIELD_VALUE",
    categoryField
});

export const sortValue = (sort_by) => ({
    type: "GET_SORT_BY_VALUE",
    sort_by
});

export const areaValue = (value) => ({
    type: "GET_AREA_VALUE",
    value
});


