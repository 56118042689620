/**
 *  Contact Us Page
 */
import React, {Component} from 'react';
import ContactDetail from '../../widgets/ContactDetail';
import PageTitle from '../../widgets/PageTitle';
import {Row, Container} from 'reactstrap';
import {withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";

class Contactus extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className="site-content">
                <div
                    className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
                    <Container>
                        <Row className="intro-title align-items-center intro-section-center">

                            <Helmet>
                                <title>Επικοινωνία «Αφοί Μιχαήλ» </title>
                                <meta name="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                                <meta itemProp="name" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>

                                <meta itemProp="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                                <meta itemProp="image" content="https://afoimichail.com//mainSitePage.jpg"/>

                                <meta property="og:url"
                                      content={`https://afoimichail.com/${window.location.hash}`}/>
                                <meta property="og:type" content="website"/>
                                <meta property="og:title" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>
                                <meta property="og:description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>
                                <meta property="og:image"
                                      content="https://afoimichail.com/mainSitePage.jpg"/>

                            </Helmet>

                            <PageTitle title="Επικοινωνία"/>
                        </Row>
                    </Container>
                </div>
                <div className="content-wrapper">
                    <div className="section-wrapper section-pt pb-6">
                        <ContactDetail/>
                    </div>


                </div>
            </div>
        )
    }
}

export default withRouter(Contactus);

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyBfqH9LV7E0y3DvblpSUoAHkA33S7pWCII'
// })(Contactus);
