/**
 * Property Slider Widget
 */
import React, {Component} from 'react';
import Slider from "react-slick";
import {  Col } from 'reactstrap';
import MyProducts from '../api/product.json';
import { ToastContainer } from 'react-toastify';

class ProductSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.refreshFacebookPage = this.refreshFacebookPage.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.refreshFacebookPage();

    }


    refreshFacebookPage() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '770753807046943',
                status     : true,
                xfbml      : true,
                version    : 'v2.7'
            });
        };
        window.FB.XFBML.parse();
    }

    render() {

        const settings = this.props.settings;

        return(

            <Col sm={12}>
                <ToastContainer autoClose={1000} />
                <div className="products-listing-items-wrapper products-listing-carousel">
                    <div className="products" data-nav-arrow="true" data-items={4} data-md-items={3} data-sm-items={3} data-xs-items={2} data-xx-items={1} data-space={20}>
                        <Slider {...settings} className="slider-spacing-10 slider-arrow-hover">

                            {MyProducts.map((product, index) =>
                                <div className="item">
                                    <div className="product product_tag-black product-hover-style-default product-hover-button-style-dark product_title_type-single_line product_icon_type-line-icon">
                                        <div className="product-inner element-hovered">
                                            <div className="product-thumbnail">
                                                <div className="product-thumbnail-inner">
                                                    <a href={`https://afoimichail.com/#/properties/${product.categoryEnglish}/${product.id}`}> {product.pictures[0] ?
                                                        <div className="product-thumbnail-main">
                                                            <img src={require(`../assets/real-estate/${product.pictures[0]}`)} className="img-fluid" alt=""/>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                        {product.pictures[1]  ?
                                                            <div className="product-thumbnail-swap">
                                                                <img src={require(`../assets/real-estate/${product.pictures[1]}`)} className="img-fluid" alt="" />
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </a>
                                                </div>

                                                <div className="product-actions">

                                                    <div className="product-action product-action-add-to-cart" style={{backgroundColor:'#fff2d5'}}>
                                                        <a href={`https://afoimichail.com/#/properties/${product.categoryEnglish}/${product.id}`}>{product.name}</a>
z
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-info">
                                                <span className="ciyashop-product-category">
                                                    {product.category} - {product.status}
                                                </span>
                                                <h3 className="product-name">

                                                    <a href={`https://afoimichail.com/#/properties/${product.categoryEnglish}/${product.id}`}>{product.name}</a>
                                                </h3>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}

                        </Slider>
                    </div>
                </div>
            </Col>

        )
    }
}
export default ProductSlider;




// function ProductSlider(props) {
//     const settings = props.settings;
//
//
//     return (
//
//
//     )
//
//
// }
// export default ProductSlider;
//
