/**
 *  Services
 */
import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";


class ServicesMainPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        return (
            <div className="site-content">
                <Container id="services">
                    <Row>
                        <Col sm={12} className="col-sm-12">
                            <div className="section-title text-center mb-2">
                                <h1 className="title"><a href="https://afoimichail.com/#/services/" rel="noopener noreferrer" > Υπηρεσίες</a></h1>
                                <p >η Αφοί Μιχαήλ παρέχουν εξειδικευμένη διαχείριση ακινήτων στη περιοχή της Λαμίας</p>
                            </div>
                        </Col>
                    </Row>


                    <Row>

                        <div className="col-lg-6">

                            <div className="box wow fadeInLeft">
                                <div className="icon"><i className="fa fa-handshake-o"/></div>
                                <h4 className="title">
                                    <a href="https://afoimichail.com/#/properties" rel="noopener noreferrer">Πώληση Ακινήτων</a>
                                </h4>
                                {/*<p className="description">Voluptatum deleniti atque corrupti quos dolores et quas*/}
                                {/*    molestias excepturi sint occaecati cupiditate non provident etiro rabeta*/}
                                {/*    lingo.</p>*/}
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="box wow fadeInRight">
                                <div className="icon"><i className="fa fa-star-o"/></div>
                                <h4 className="title">
                                    <a href="https://afoimichail.com/#/properties" rel="noopener noreferrer">Ενοικίαση Ακινήτων</a>
                                </h4>
                                {/*<p className="description">Minim veniam, quis nostrud exercitation ullamco laboris*/}
                                {/*    nisi*/}
                                {/*    ut aliquip ex ea commodo consequat tarad limino ata nodera clas.</p>*/}
                            </div>
                        </div>

                    </Row>
                </Container>
            </div>
        )
    }
};
export default ServicesMainPage;


