/**
 * About Banner 2
 */
import React from 'react';
import {Row, Col,} from 'reactstrap';

function AboutBanner2() {

    return (
        <div className="section-wrapper section-ptb">
            <div className="container">
                <Row>
                    <Col lg={5}>
                        <img src={require(`../assets/images/about-us.jpg`)} className="img-fluid" alt=""/>
                    </Col>
                    <Col lg={7} className="mt-4 mt-lg-0">
                        <div className="section-title mb-3">
                            <h2 className="font-bold">Ιστορικό</h2>
                        </div>
                        <div className="content" style={{textAlign: 'justify', margin: '15px', fontSize:'18px'}}>
                            <p>
                                Η <a href="https://afoimichail.com/#/" rel="noopener noreferrer">«Αφοί Μιχαήλ»</a>,
                                βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση
                                των προσφερομένων κατασκευαστικών υπηρεσιών
                                από έμπειρο και εξειδικευμένο προσωπικό,
                                με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                απαιτήσεων των πελατών της.

                            </p>

                            <div className="ciyashop_list_wrapper mb-3">
                                <ul className="ciyashop_list list icon-list-type-none">
                                    <li>
                                        <i className="fa fa-check-square"/>
                                        <p className="ciyashop-list-info">
                                            Εξειδίκευση </p>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-square"/>
                                        <p className="ciyashop-list-info">
                                            Εμπειρία </p>
                                    </li>
                                    <li>
                                        <i className="fa fa-check-square"/>
                                        <p className="ciyashop-list-info">
                                            Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
        </div>


    )

}

export default AboutBanner2;
