/**
 *  AboutCompany
 */
import React, {Component} from 'react';
import aboutImage from '../../assets/real-estate/img/about-img.jpg';
import {Col, Container, Row} from "reactstrap";
import {Animated} from "react-animated-css";

class AboutCompany extends Component {


    render() {

        return (

            <div className="site-content" id="about">
                <Container id="services">
                    <Row>
                        <Col sm={12} className="col-sm-12">
                            <div className="section-title text-center mb-2">
                                <h1 className="title"><a href="https://afoimichail.com/#/about/" rel="noopener noreferrer">Σχετικά με την εταιρία</a></h1>
                                <p><h6>Πολύχρονη - Εξειδικευμένη Εμπειρία στο Κατασκευαστικό Τομέα </h6></p>
                            </div>
                        </Col>
                    </Row>

                    <Row>

                        <Col lg={6}>
                            <div className="about-img">
                                <Animated animationIn="lightSpeedIn" animationOut="zoomOutDown"
                                          animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                                    <img src={aboutImage} alt=""/>
                                </Animated>
                            </div>
                        </Col>

                        <Col lg={6} >
                            <div className="content" style={{textAlign: 'justify', margin:'15px'}}>

                                <h1><a href="https://afoimichail.com/#/about/" rel="noopener noreferrer" > «Αφοί Μιχαήλ»</a></h1>
                                <h3>βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση
                                    των προσφερομένων κατασκευαστικών υπηρεσιών
                                    από έμπειρο και εξειδικευμένο προσωπικό,
                                    με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                    απαιτήσεων των πελατών της.</h3>

                                <ul>
                                    <li><h4><i className="ion-android-checkmark-circle"/> Αξιοπιστία</h4>
                                    </li>
                                    <li><h4><i className="ion-android-checkmark-circle"/>  Υπευθυνότητα</h4>
                                    </li>
                                    <li><h4><i className="ion-android-checkmark-circle"/>  Εμπειρία</h4>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
};
export default AboutCompany;


