
// Get Min & Max Data in Json
//areaApartment
export const uniqueMinMaxArea = (products) => {
    let minimum = 40, maximum = 300;

    // eslint-disable-next-line
    products.map((product, index) => {
        let v = product.areaApartment;
        if(v < minimum)
        {
            minimum=v;
        }
        else
        {
            // eslint-disable-next-line
            minimum=minimum;
        }
        if(v > maximum)
        {
            maximum=v;
        }
        else
        {
            // eslint-disable-next-line
            maximum=maximum;
        }
    })
    return {'min':minimum, 'max':maximum};
};

// Get CategoryField Unique Data
export const uniqueCategoryField = (products) => {
    var uniqueCategorysField = [];

    // eslint-disable-next-line
    products.map((product) => {
        // eslint-disable-next-line
        if (product.categoryField.length > 0 && product.categoryField) {
            // eslint-disable-next-line
            product.categoryField.map((categorysField) => {
                // eslint-disable-next-line
                if (uniqueCategorysField.indexOf(categorysField) === -1) {
                    uniqueCategorysField.push(categorysField);
                }
            })
        }
    })
    return uniqueCategorysField;
};

// All Filter Used And Get Final Response
export const getFilterProductsdata = (data, { category, value, sortOrder,ratings, search}) => {

    return data.products.filter(product => {

        let categoryMatchValue;
        if(product.tags)
            categoryMatchValue = product.tags.some(tag => category.includes(tag))
        else
            categoryMatchValue = true;



        let searchMatchValue;
        if(product.name) {
            if(search === search.toLowerCase())
            {
                searchMatchValue=product.name.toLowerCase().indexOf(search.toLowerCase()) > -1
            }
            else
            {
                searchMatchValue=product.name.toUpperCase().indexOf(search.toUpperCase()) > -1
            }
        }
        else
        {
            searchMatchValue = false;
        }


        let ratingMatchValue;
        if(product.rating === ratings) {
            ratingMatchValue = true;
        }
        else if(ratings === "")
        {
            ratingMatchValue = true;
        }
        else{
            ratingMatchValue = false;
        }



        const startPriceMatchValue = typeof value.min !== 'number' || value.min <= product.areaApartment;
        const endPriceMatchValue = typeof value.max !== 'number' || product.areaApartment <= value.max;

        let filtercheck=JSON.parse(localStorage.state).filters;

        if(filtercheck.category.length > 0 && filtercheck.ratings.length > 0 )
        {
            return  categoryMatchValue && ratingMatchValue && startPriceMatchValue && endPriceMatchValue && searchMatchValue;
        }

        if(filtercheck.category.length > 0  )
        {
            return  categoryMatchValue &&  startPriceMatchValue && endPriceMatchValue  && searchMatchValue;
        }

        if(filtercheck.category.length > 0 && filtercheck.ratings.length > 0  )
        {
            return  categoryMatchValue && ratingMatchValue && startPriceMatchValue && endPriceMatchValue && searchMatchValue;
        }

        if(filtercheck.category.length > 0 && filtercheck.ratings.length > 0 )
        {
            return  categoryMatchValue &&  ratingMatchValue && startPriceMatchValue && endPriceMatchValue && searchMatchValue;
        }


        if(filtercheck.category.length > 0  )
        {
            return  categoryMatchValue && startPriceMatchValue && endPriceMatchValue && searchMatchValue;
        }

        if(filtercheck.category.length > 0 )
        {
            return  categoryMatchValue && startPriceMatchValue && endPriceMatchValue  && searchMatchValue;
        }

        if(filtercheck.category.length > 0 && filtercheck.ratings.length > 0)
        {
            return  categoryMatchValue && ratingMatchValue   && startPriceMatchValue && endPriceMatchValue  && searchMatchValue;
        }

        if( filtercheck.ratings.length > 0)
        {
            return  ratingMatchValue   && startPriceMatchValue && endPriceMatchValue  && searchMatchValue;
        }

        if( filtercheck.ratings.length > 0)
        {
            return ratingMatchValue   && startPriceMatchValue && endPriceMatchValue  && searchMatchValue;
        }

        if(filtercheck.category.length > 0)
        {
            return  categoryMatchValue   && startPriceMatchValue && endPriceMatchValue  && searchMatchValue;
        }

        if(filtercheck.ratings.length > 0)
        {
            return  ratingMatchValue   && startPriceMatchValue && endPriceMatchValue  && searchMatchValue;
        }


        else

        {
            return startPriceMatchValue && endPriceMatchValue  && searchMatchValue;
        }

    }).sort((sortpro1, sortpro2) => {
        if (sortOrder === 'Areahigh') {
            return sortpro2.areaApartment < sortpro1.areaApartment ? -1 : 1;
        } else if (sortOrder === 'Arealow') {
            return sortpro2.areaApartment > sortpro1.areaApartment ? -1 : 1;
        } else if (sortOrder === 'NewProduct') {
            return sortpro2.id < sortpro1.id ? -1 : 1;
        } else{
            return sortpro2.id > sortpro1.id ? -1 : 1;
        }
    });
};