import React, {Component, Fragment} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import Contactus from "../ContactUs/Contactus";
import HomePage from "../HomePage/HomePage";
import Services from "../Services/Services";
import Aboutus from "../AboutUs/Aboutus";
import PropertyDetail from "../properties/property-detail";
import PropertyPage from '../properties';
import ComingSoon from "../Pages/ComingSoon";
import Maintenance from "../Pages/Maintenance";
import PageNotFound from "../Pages/PageNotFound";
import PolicyTerms from "../PolicyTerms/PolicyTerms";

class RealEstateApp extends Component {
    render() {
        return (
            <div className="body">

                <Fragment>

                    <div>

                        <Header/>

                        <main id="main">

                            <Switch>
                                <Route exact path="/" component={HomePage}/>
                                <Route exact path="/about" component={Aboutus}/>
                                <Route exact path="/services" component={Services}/>
                                <Route exact path="/properties" component={PropertyPage}/>
                                <Route exact path="/contact" component={Contactus}/>
                                <Route exact path={`/properties/:categoryEnglish/:id`} component={PropertyDetail} />
                                <Route exact path="/policyTerms" component={PolicyTerms} />

                                {/*    /!*Page Not Found*!/*/}
                                <Route exact path="/comingSoon" component={ComingSoon} />
                                <Route exact path="/maintenance" component={Maintenance} />
                                <Route exact  component={PageNotFound} />

                            </Switch>
                        </main>
                        <Footer/>

                    </div>
                </Fragment>


            </div>
        );
    }
}

export default withRouter(RealEstateApp);
