/**
 * InterestingFacts Widget
 */
import React from 'react';
import { Row, Col } from 'reactstrap';

function InterestingFacts() {

    return (
        <div className="section-wrapper section-ptb bg-gray">
                <div className="container">
                <Row>
                    <Col sm={12}>
                    <div className="section-title text-center">
                        <h2 className="font-bold title">Δεύσμευση</h2>
                        <p style={{textAlign: 'justify', margin:'15px', fontSize:'18px'}} className="text-center">Η <a href="https://afoimichail.com/#/" rel="noopener noreferrer">«Αφοί Μιχαήλ»</a> δεσμεύται για την ικανοποίηση των αναγκών των πελατών της.</p>
                    </div>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col lg={4} md={6}>
                    <div className="mb-4 mb-md-6 mt-4 mt-md-6 ciyashop_info_box ciyashop_info_box-layout-style_2 ciyashop_info_box-content_alignment-right ciyashop_info_box-with-icon ciyashop_info_box-icon-source-font ciyashop_info_box-icon-style-border ciyashop_info_box-icon-size-md ciyashop_info_box-icon-shape-round ciyashop_info_box-icon_position-right info_box-step_position-above_title">
                        <div className="ciyashop_info_box-inner clearfix">
                        <div className="ciyashop_info_box-icon">
                            <div className="ciyashop_info_box-icon-wrap">
                            <div className="ciyashop_info_box-icon-outer">
                                <div className="ciyashop_info_box-icon-inner" style={{borderColor: 'dodgerblue', borderWidth: '2px', borderStyle: 'solid'}}>
                                <i className="fa fa-exchange" style={{color: 'dodgerblue'}} /> </div>
                            </div>
                            </div>
                        </div>
                        <div className="ciyashop_info_box-content">
                            <div className="ciyashop_info_box-content-wrap">
                            <div className="ciyashop_info_box-content-inner">
                                <div className="ciyashop_info_box-step-wrapper">
                                <span className="ciyashop_info_box-step">
                                    01 </span>
                                </div>
                                <h4 className="ciyashop_info_box-title" style={{color: '#323232'}}>
                                Χωρίς μεσάζοντες μεσίτες και ενδιάμεσες εταιρίες ακινήτων </h4>
                                <div className="ciyashop_info_box-description">
                                {/*<p>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet</p>*/}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mb-4 mb-md-6 ciyashop_info_box ciyashop_info_box-layout-style_2 ciyashop_info_box-content_alignment-right ciyashop_info_box-with-icon ciyashop_info_box-icon-source-font ciyashop_info_box-icon-style-border ciyashop_info_box-icon-size-md ciyashop_info_box-icon-shape-round ciyashop_info_box-icon_position-right info_box-step_position-above_title">
                        <div className="ciyashop_info_box-inner clearfix">
                        <div className="ciyashop_info_box-icon">
                            <div className="ciyashop_info_box-icon-wrap">
                            <div className="ciyashop_info_box-icon-outer">
                                <div className="ciyashop_info_box-icon-inner" style={{borderColor: 'dodgerblue', borderWidth: '2px', borderStyle: 'solid'}}>
                                <i className="fa fa-retweet" style={{color: 'dodgerblue'}} /> </div>
                            </div>
                            </div>
                        </div>
                        <div className="ciyashop_info_box-content">
                            <div className="ciyashop_info_box-content-wrap">
                            <div className="ciyashop_info_box-content-inner">
                                <div className="ciyashop_info_box-step-wrapper">
                                <span className="ciyashop_info_box-step">
                                    02 </span>
                                </div>
                                <h4 className="ciyashop_info_box-title" style={{color: '#323232'}}>
                                Αξιοπιστία </h4>
                                <div className="ciyashop_info_box-description">
                                {/*<p>Plenty of time to change your mind odio dignissimos ducimus qui*/}
                                {/*    blanditiis praesentium.</p>*/}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mb-4 mb-md-6 ciyashop_info_box ciyashop_info_box-layout-style_2 ciyashop_info_box-content_alignment-right ciyashop_info_box-with-icon ciyashop_info_box-icon-source-font ciyashop_info_box-icon-style-border ciyashop_info_box-icon-size-md ciyashop_info_box-icon-shape-round ciyashop_info_box-icon_position-right info_box-step_position-above_title">
                        <div className="ciyashop_info_box-inner clearfix">
                        <div className="ciyashop_info_box-icon">
                            <div className="ciyashop_info_box-icon-wrap">
                            <div className="ciyashop_info_box-icon-outer">
                                <div className="ciyashop_info_box-icon-inner" style={{borderColor: 'dodgerblue', borderWidth: '2px', borderStyle: 'solid'}}>
                                <i className="fa fa-inbox" style={{color: 'dodgerblue'}} /> </div>
                            </div>
                            </div>
                        </div>
                        <div className="ciyashop_info_box-content">
                            <div className="ciyashop_info_box-content-wrap">
                            <div className="ciyashop_info_box-content-inner">
                                <div className="ciyashop_info_box-step-wrapper">
                                <span className="ciyashop_info_box-step">
                                    03 </span>
                                </div>
                                <h4 className="ciyashop_info_box-title" style={{color: '#323232'}}>
                                Υπευθυνότητα </h4>
                                <div className="ciyashop_info_box-description">
                                {/*<p>Sent straight to your inbox within 2 hours many desktop*/}
                                {/*    publishing packages.</p>*/}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </Col>
                    <Col sm={4} className="d-none d-lg-block">
                    <img src={require(`../assets/images/about-us-1.png`)} className="img-fluid" alt="" />
                    </Col>
                    <Col lg={4} md={6}>
                    <div className="mb-4 mb-md-6 mt-0 ciyashop_info_box ciyashop_info_box-layout-style_2 ciyashop_info_box-content_alignment-left ciyashop_info_box-with-icon ciyashop_info_box-icon-source-font ciyashop_info_box-icon-style-border ciyashop_info_box-icon-size-md ciyashop_info_box-icon-shape-round ciyashop_info_box-icon_position-left info_box-step_position-above_title">
                        <div className="ciyashop_info_box-inner clearfix left-info-icon-bottom">
                        <div className="ciyashop_info_box-icon">
                            <div className="ciyashop_info_box-icon-wrap">
                            <div className="ciyashop_info_box-icon-outer">
                                <div className="ciyashop_info_box-icon-inner" style={{borderColor: 'dodgerblue', borderWidth: '2px', borderStyle: 'solid'}}>
                                <i className="fa fa-gift" style={{color: 'dodgerblue'}} /> </div>
                            </div>
                            </div>
                        </div>
                        <div className="ciyashop_info_box-content">
                            <div className="ciyashop_info_box-content-wrap">
                            <div className="ciyashop_info_box-content-inner">
                                <div className="ciyashop_info_box-step-wrapper">
                                <span className="ciyashop_info_box-step">
                                    04 </span>
                                </div>
                                <h4 className="ciyashop_info_box-title" style={{color: '#323232'}}>
                                Σύγχρονες Ανάγκες Πελατών </h4>
                                <div className="ciyashop_info_box-description">
                                {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>*/}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mb-4 mb-md-6 ciyashop_info_box ciyashop_info_box-layout-style_2 ciyashop_info_box-content_alignment-left ciyashop_info_box-with-icon ciyashop_info_box-icon-source-font ciyashop_info_box-icon-style-border ciyashop_info_box-icon-size-md ciyashop_info_box-icon-shape-round ciyashop_info_box-icon_position-left info_box-step_position-above_title">
                        <div className="ciyashop_info_box-inner clearfix left-info-icon-bottom">
                        <div className="ciyashop_info_box-icon">
                            <div className="ciyashop_info_box-icon-wrap">
                            <div className="ciyashop_info_box-icon-outer">
                                <div className="ciyashop_info_box-icon-inner" style={{borderColor: 'dodgerblue', borderWidth: '2px', borderStyle: 'solid'}}>
                                <i className="fa fa-usd" style={{color: 'dodgerblue'}} /> </div>
                            </div>
                            </div>
                        </div>
                        <div className="ciyashop_info_box-content">
                            <div className="ciyashop_info_box-content-wrap">
                            <div className="ciyashop_info_box-content-inner">
                                <div className="ciyashop_info_box-step-wrapper">
                                <span className="ciyashop_info_box-step">
                                    05 </span>
                                </div>
                                <h4 className="ciyashop_info_box-title" style={{color: '#323232'}}>
                                    Προνομιακές Τιμές </h4>
                                <div className="ciyashop_info_box-description">
                                {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>*/}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mb-0 mb-md-6 ciyashop_info_box ciyashop_info_box-layout-style_2 ciyashop_info_box-content_alignment-left ciyashop_info_box-with-icon ciyashop_info_box-icon-source-font ciyashop_info_box-icon-style-border ciyashop_info_box-icon-size-md ciyashop_info_box-icon-shape-round ciyashop_info_box-icon_position-left info_box-step_position-above_title">
                        <div className="ciyashop_info_box-inner clearfix left-info-icon-bottom">
                        <div className="ciyashop_info_box-icon">
                            <div className="ciyashop_info_box-icon-wrap">
                            <div className="ciyashop_info_box-icon-outer">
                                <div className="ciyashop_info_box-icon-inner" style={{borderColor: 'dodgerblue', borderWidth: '2px', borderStyle: 'solid'}}>
                                <i className="fa fa-truck" style={{color: 'dodgerblue'}} /> </div>
                            </div>
                            </div>
                        </div>
                        <div className="ciyashop_info_box-content">
                            <div className="ciyashop_info_box-content-wrap">
                            <div className="ciyashop_info_box-content-inner">
                                <div className="ciyashop_info_box-step-wrapper">
                                <span className="ciyashop_info_box-step">
                                    06 </span>
                                </div>
                                <h4 className="ciyashop_info_box-title" style={{color: '#323232'}}>
                                    Ραντεβού Παρουσίασης Ακινήτων </h4>
                                <div className="ciyashop_info_box-description">
                                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>*/}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </Col>
                </Row>
                </div>
            </div>
        )

    }

export default InterestingFacts;
