/**
 * Property Page Side Bar Filter
 */
import React , {Component} from 'react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { uniqueCategoryField,  uniqueMinMaxArea} from '../../services';
import { categoryFieldValue, searchValue , areaValue} from '../../actions/filter';


class SideFilter extends Component {

    constructor(props) {
        super(props);
        this.state={
            SearchValue:''
        }
    }
    componentDidMount(){
        this.setState({
            ...this.state,
            SearchValue:''
        })
        this.props.searchValue('');
        this.nameInput.focus();
    }

    SearchTextchange(SearchText)
    {
        this.setState({
            ...this.state,
            SearchValue:SearchText.target.value
        });
        this.props.searchValue(SearchText.target.value);
    }

    render(){

        if(this.props.filters.value.max > 2000)
        {
            this.props.filters.value.max = 2000;
        }
        if(this.props.filters.value.min < 5)
        {
            this.props.filters.value.min = 5;
        }
       return (
           <div>
                <div className="widget">
                <h4 className="widget-title">Αναζήτηση</h4>
                    <input type="text" ref={(input) => { this.nameInput = input; }}  className="form-control"  value={this.state.SearchValue}  onChange={this.SearchTextchange.bind(this)} placeholder="Αναζητήστε ένα Ακίνητο με τ.μ." />
                </div>

                <div className="widget widget_price_filter">
                    <h4 className="widget-title">Εμβαδό</h4>
                    <div classs="shop-filter shop-filter-product-price widget_price_filter">
                        <div className="shop-filter-wrapper">

                        <div className="price_slider_wrapper">
                            <InputRange
                                maxValue={this.props.areasApartment.max}
                                minValue={this.props.areasApartment.min}
                                value={this.props.filters.value}
                                onChange={value => this.props.areaValue({ value })} />
                        </div>
                        </div>
                    </div>
                </div>

            </div>
       )
    }
}

const mapDispatchToProps  = state => ({
    categorysField: uniqueCategoryField(state.data.products),
    areasApartment: uniqueMinMaxArea(state.data.products),
    filters: state.filters
})
export default connect(
    mapDispatchToProps ,
    {categoryFieldValue, searchValue, areaValue}
)(SideFilter);

