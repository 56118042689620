/**
 *  Property Detail Page
 */
import React, {Component} from 'react';
import PostDetail from '../../templates/post-detail';
import ProductSlider from '../../widgets/ProductSlider';
import {Link, withRouter} from 'react-router-dom';
import {Row, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import productdata from '../../api/product';
import classnames from 'classnames';
import GeneralInfo from "../GeneralInfo/GeneralInfo";
import {Helmet} from "react-helmet-async";

const relatedslider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

class PropertyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AllProduct: productdata,
            ProductId: parseInt(this.props.match.params.id),
            CurrentProduct: null,
            activeTab: '1',

        }
        this.toggle = this.toggle.bind(this);
        this.refreshFacebookPage = this.refreshFacebookPage.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0);


        let CurrentProductId = this.state.ProductId;
        let allproductdata = this.state.AllProduct;
        if (allproductdata && allproductdata.length > 0) {
            for (let product of allproductdata) {
                if (product.id === CurrentProductId) {
                    this.setState({
                        ...this.state,
                        CurrentProduct: product

                    });
                    this.refreshFacebookPage()
                }
            }
        }

    }

    refreshFacebookPage() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '770753807046943',
                status     : true,
                xfbml      : true,
                version    : 'v2.7'
            });
        };


        window.FB.XFBML.parse();
    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const Productedit = this.state.CurrentProduct;

        return (
            <div>
                {Productedit !== null ?
                    <div className="site-content">
                        <div className="inner-intro">
                            <Container>
                                <Row className="intro-title align-items-center">
                                    <div className="col-12">
                                        <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                                            <li className="home">
                                    <span>
                                    <Link className="bread-link bread-home" to="/">Home</Link>
                                    </span>
                                            </li>
                                            <li><span>{Productedit.category}</span></li>
                                            <li><span>{Productedit.name}</span></li>
                                        </ul>
                                    </div>


                                </Row>
                            </Container>
                        </div>
                        <div className="content-wrapper section-ptb">
                            <Container>

                                <Helmet>
                                    <meta charSet="utf-8"/>
                                    <title>{Productedit.category} {Productedit.name} </title>
                                    <meta name="description"
                                          content={`${Productedit.category} 
                                              - ${Productedit.name} 
                                              - ${Productedit.totalDescription} 
                                              - ${Productedit.status} -  ${Productedit.areaApartment}`}/>

                                    <meta itemProp="name"  content={`${Productedit.category} ${Productedit.name}`}/>
                                    <meta itemProp="description" content={`${Productedit.category} 
                                              - ${Productedit.name} 
                                              - ${Productedit.totalDescription} 
                                              - ${Productedit.status} -  ${Productedit.areaApartment}`}/>

                                    <meta property="og:type" content="website" />
                                    <meta itemProp="image" content={this.state.newImage}/>
                                    <meta property="og:url"
                                          content={`https://afoimichail.com/${window.location.hash}`}/>                                    <meta property="og:type" content="website"/>
                                    <meta property="og:title" content={`${Productedit.category} ${Productedit.name}`}/>
                                    <meta property="og:description" content={`${Productedit.category} 
                                              - ${Productedit.name} 
                                              - ${Productedit.totalDescription} 
                                              - ${Productedit.status} -  ${Productedit.areaApartment}`}/>
                                    <meta property="og:image"
                                          content={`https://afoimichail.com${require(`../../assets/real-estate/${Productedit.pictures[0]}`)}`}/>

                                </Helmet>

                                <PostDetail product={Productedit} tabid={this.state.activeTab}/>

                                <div className="product-content-bottom">

                                    <Nav tabs>
                                        <NavItem active>
                                            <NavLink className={classnames({active: this.state.activeTab === '1'})}
                                                     onClick={() => {
                                                         this.toggle('1');
                                                     }}>Αναλυτικά Χαρακτηριστικά </NavLink>
                                        </NavItem>

                                        <NavItem disabled>
                                            <NavLink className={classnames({active: this.state.activeTab === '2'})}
                                                     onClick={() => {
                                                         this.toggle('2');
                                                     }}>Περιγραφή</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab} style={{textAlign: 'justify'}}>
                                        <TabPane tabId="1">
                                            <div className="tab-content" id="myTabContent">
                                                <Row>
                                                    <Col sm={6} md={4}>
                                                        <div className="tab-pane fade show active" id="description"
                                                             role="tabpanel" aria-labelledby="home-tab" >
                                                            <h2>Χαρακτηριστικά Ακινήτου</h2>
                                                            <p><strong><i
                                                                className="fa fa-building" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;{Productedit.name}
                                                            </strong></p>
                                                            <p><strong><i
                                                                className="fa fa-bell" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Τύπος: &nbsp;&nbsp;{Productedit.category}
                                                            </strong></p>
                                                            <p><strong><i
                                                                className="fa fa-key" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Κατάσταση: &nbsp;&nbsp;{Productedit.status}
                                                            </strong></p>
                                                            <p><strong><i
                                                                className="fa fa-building-o" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Εμβαδό: &nbsp;&nbsp;{Productedit.square}
                                                            </strong></p>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} md={4}>
                                                        <div className="tab-pane fade show active" id="description"
                                                             role="tabpanel" aria-labelledby="home-tab">
                                                            <h2>&nbsp;</h2>


                                                            <p><strong><i
                                                                className="fa fa-server" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Όροφος: &nbsp;&nbsp;{Productedit.floor}
                                                            </strong></p>
                                                            <p><strong><i
                                                                className="fa fa-hotel" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Δωμάτια: &nbsp;&nbsp;{Productedit.rooms}
                                                            </strong></p>
                                                            <p><strong><i
                                                                className="fa fa-bath" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Μπάνιο: &nbsp;&nbsp;{Productedit.wc}
                                                            </strong></p>
                                                            <p><strong><i
                                                                className="fa fa-heart-o" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Τύπος Θέρμανσης: &nbsp;&nbsp;{Productedit.heating}
                                                            </strong></p>


                                                        </div>
                                                    </Col>
                                                    <Col sm={6} md={4}>
                                                        <div className="tab-pane fade show active" id="description"
                                                             role="tabpanel" aria-labelledby="home-tab">
                                                            <h2>&nbsp;</h2>
                                                            <p><strong><i
                                                                className="fa fa-car" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Χώρος Στάθμευσης: &nbsp;&nbsp;{Productedit.parking}
                                                            </strong></p>
                                                            <p><strong><i
                                                                className="fa fa-archive" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Αποθήκη: &nbsp;&nbsp;{Productedit.warehouse}
                                                            </strong></p>
                                                            <p><strong><i
                                                                className="fa fa-fire" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Τζάκι: &nbsp;&nbsp;{Productedit.fireplace}
                                                            </strong></p>
                                                            <p><strong><i
                                                                className="fa fa-umbrella" style={{color:'dodgerblue'}}/>&nbsp;&nbsp;Air Condition: &nbsp;&nbsp;{Productedit.airCondition}
                                                            </strong></p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </TabPane>
                                        <TabPane tabId="2">
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="description"
                                                     role="tabpanel" aria-labelledby="home-tab">
                                                    <h2>{Productedit.category} , {Productedit.status}</h2>
                                                    <p><strong>{Productedit.totalDescription}</strong></p>


                                                </div>

                                            </div>
                                        </TabPane>

                                    </TabContent>

                                    <GeneralInfo />

                                    <div className="related products">
                                        <h2>Δείτε Ακόμη ... </h2>
                                        <div className="row">
                                            <ProductSlider settings={relatedslider}/>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                    :
                    null
                }
            </div>


        )
    }
}

export default withRouter(PropertyDetail);
