/**
 * Shop Page Social Info
 */
import React, {Component} from 'react';

class SocialFacebookPage extends Component {

    constructor(props) {
        super(props);
        this.state = { };
        this.refreshFacebookPage = this.refreshFacebookPage.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.refreshFacebookPage();

    }


    refreshFacebookPage() {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '770753807046943',
                status     : true,
                xfbml      : true,
                version    : 'v2.7'
            });
        };
        window.FB.XFBML.parse();
    }


    render() {

        return (

            <>
                <div
                    className="fb-page"
                    data-href="https://www.facebook.com/afoimichail"
                    data-tabs="timeline, messages"
                    data-width="350" data-height="1000"
                    data-small-header="false" data-adapt-container-width="true"
                    data-hide-cover="false" data-show-facepile="true" data-lazy="lazy"
                >
                </div>
            </>
        )
    }
}

export default SocialFacebookPage;


