import React from 'react';
import ReactDOM from 'react-dom';

import {ProductsData} from './actions';
import './index.css';
import './Vendor.js';
import './App.css';
//Component
import {Provider} from 'react-redux';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import registerServiceWorker from './registerServiceWorker';
//import * as serviceWorker from './serviceWorker';
import servicesstore from "./services/store";

import App from "./App";

class Root extends React.Component {
    render() {
        servicesstore.dispatch(ProductsData());
        return (
            <Provider store={servicesstore}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" component={App}/>
                    </Switch>
                </BrowserRouter>
            </Provider>
        );
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));

registerServiceWorker();


//import "babel-polyfill";
