/**
 * Property List Widget
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

class ProductList extends Component {
    constructor(props) {
        super(props);


        this.state = {
            open: false,
            quantity: 1,
            image: ''
        }
    }

    render() {
        const {product} = this.props;

        return (
            <div key={1} className={this.props.layoutstyle}>
                <ToastContainer autoClose={1000}/>
                <div
                    className="product product_tag-black product-hover-style-default product-hover-button-style-light product_title_type-single_line product_icon_type-line-icon">
                    <div className="product-inner element-hovered">
                        <div className="product-thumbnail">
                            <div className="product-thumbnail-inner">
                                <Link to={`/properties/${product.categoryEnglish}/${product.id}`}>
                                    {product.pictures[0] ?
                                        <div className="product-thumbnail-main">
                                            <img src={require(`../assets/real-estate/${product.pictures[0]}`)}
                                                 className="img-fluid" alt=""/>
                                        </div>
                                        :
                                        null
                                    }
                                    {product.pictures[1] ?
                                        <div className="product-thumbnail-swap">
                                            <img src={require(`../assets/real-estate/${product.pictures[1]}`)}
                                                 className="img-fluid" alt=""/>
                                        </div>
                                        :
                                        null
                                    }
                                </Link>
                            </div>

                            <div className="product-actions">
                                <div className="product-action product-action-add-to-cart" style={{backgroundColor: '#fff2d5'}}>
                                    <Link
                                        to={`/properties/${product.categoryEnglish}/${product.id}`}>{product.category}<br/>{product.name}</Link>
                                </div>

                            </div>
                        </div>
                        <div className="product-info">
                            {product.tags ?
                                <span className="ciyashop-product-category">
                                    {product.tags.map((tag, index) =>
                                        <span>{tag}{index === product.tags.length - 1 ? '' : ','}</span>
                                    )}
                                </span>
                                : null}
                            {product.name ?
                                <h3 className="product-name">
                                    <Link to={`/properties/${product.categoryEnglish}/${product.id}`}>
                                        {product.category} - {product.name}
                                    </Link>
                                </h3>
                                : null}

                            {product.description ?
                                <div className="product-details__short-description">
                                    <p>{product.description}
                                    </p>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ProductList;
