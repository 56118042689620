/**
 *  header-menu and sidebar menu data
 */
export default [

    {

        "menu_title": "ΑΡΧΙΚΗ",
        "path": "/",
        "type": "subMenu",
        "mega": true,
        "icon": "home",
        "child_routes": null
    },

    {
        "menu_title": "Η ΕΤΑΙΡΙΑ",
        "path": "/about",
        "type": "subMenu",
        "mega": true,
        "icon": "perm_identity",
        "child_routes": null

    },


    {
        "menu_title": "ΥΠΗΡΕΣΙΕΣ",
        "path": "/services",
        "type": "subMenu",
        "mega": true,
        "icon": "perm_identity",
        "child_routes": null

    },

    {
        "menu_title": "ΑΚΙΝΗΤΑ",
        "path": "/properties",
        "type": "subMenu",
        "mega": true,
        "icon": "perm_identity",
        "child_routes": null

    },


    {
        "menu_title": "ΕΠΙΚΟΙΝΩΝΙΑ",
        "path": "/contact",
        "type": "subMenu",
        "mega": true,
        "child_routes": null
    }
]
