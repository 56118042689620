/**
 *  Maintenance Page
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Row, Col,Container } from 'reactstrap';
import maintenanceimg from '../../assets/images/maintenance.png';
import {Helmet} from "react-helmet";


class Maintenance extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div className="maintenance">

                <Helmet>
                    <title>Συντήρηση «Αφοί Μιχαήλ» </title>
                    <meta name="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα"/>

                    <meta itemProp="name" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>

                    <meta itemProp="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα"/>

                    <meta itemProp="image" content="https://afoimichail.com//mainSitePage.jpg"/>

                    <meta property="og:url"
                          content={`https://afoimichail.com/${window.location.hash}`}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>
                    <meta property="og:description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα"/>
                    <meta property="og:image"
                          content="https://afoimichail.com/mainSitePage.jpg"/>

                </Helmet>

                    <div className="site-content">
                        <div className="content-wrapper">
                            <Container>
                                <Row className="align-items-center justify-content-center">
                                        <Col md={11} lg={7} className="text-center">
                                        <div className="mntc-cs-item mntc-cs-content text-center">
                                            <h1>Η Αφοί Μιχαήλ είναι υπό συντήρηση</h1>
                                            <p>Θα επανέλθουμε σύντομα κοντά σας!! </p>
                                            <img className="mb-2 mt-3" src={maintenanceimg} title="maintenance" alt="Συντήρηση" />
                                            <p className="mt-4 mt-md-5">Ζητούμε συγνώμη για την αναστάτωση, κάνουμε ό, τι καλύτερο μπορούμε για να επαναφέρουμε τη λειτουργικότητα.</p>
                                            <div className="coming-soon-social">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/afoimichail"
                                                           rel="noopener noreferrer"> <i className="fa fa-facebook"/> </a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            )
        }
    }
export default withRouter(Maintenance);
