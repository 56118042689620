/**
 * Top Properties Product Widgets
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Row, Col, Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import MyProducts from '../api/product.json'

class TopProperties extends Component {

    render() {
        return (
            <div>
                <Container>
                    <Row className="products products-loop grid ciyashop-products-shortcode">
                        <ToastContainer autoClose={1000}/>

                        {MyProducts.map((product, index) =>
                                (index < 8) ?


                                    <Col sm={6} lg={3}>

                                        <div
                                            className="product product_tag-black product-hover-style-default product-hover-button-style-dark product_title_type-single_line product_icon_type-line-icon">
                                            <div className="product-inner element-hovered">
                                                <div className="product-thumbnail">
                                                    <div className="product-thumbnail-inner">
                                                        <Link to={`/properties/${product.categoryEnglish}/${product.id}`}>
                                                            <div className="product-thumbnail-main">
                                                                <img
                                                                    src={require(`../assets/real-estate/${product.pictures[0]}`)}
                                                                    className="img-fluid" alt="shop"/>

                                                            </div>
                                                            <div className="product-thumbnail-swap">
                                                                <img
                                                                    src={require(`../assets/real-estate/${product.pictures[1]}`)}
                                                                    className="img-fluid" alt="shop"/>

                                                            </div>

                                                        </Link>
                                                    </div>
                                                    <div className="product-actions">
                                                        <div className="product-action product-action-add-to-cart" style={{backgroundColor: '#fff2d5'}}>
                                                            <Link
                                                                to={`/properties/${product.categoryEnglish}/${product.id}`}>{product.name} </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="product-info">
                                                    <h3 className="product-name">
                                                        <Link
                                                            to={`/properties/${product.categoryEnglish}/${product.id}`}>{product.name} </Link>
                                                    </h3>

                                                    <span className="ciyashop-product-category">
                           {product.category} - {product.status}
                        </span>

                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    :
                                    <div></div>
                        )}

                    </Row>
                </Container>
            </div>
        )
    }
}

export default withRouter(TopProperties);
