/**
 *  PolicyTerms Page
 */
import React, {Component} from 'react';
import PageTitle from '../../widgets/PageTitle';
import {Col, Container, Row} from 'reactstrap';
import {withRouter} from "react-router-dom";
import SocialFacebookPage from "../../widgets/propertyfilter/SocialFacebookPage";
import {Helmet} from "react-helmet";


class PolicyTerms extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (

            <div className="site-content">
                <div
                    className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
                    <Container>
                        <div className="row intro-title align-items-center intro-section-center">
                            <Helmet>
                                <title>Όροι χρήσης – Πολιτική Προστασίας Προσωπικών Δεδομένων «Αφοί Μιχαήλ» </title>
                                <meta name="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα"/>

                                <meta itemProp="name" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>

                                <meta itemProp="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα"/>

                                <meta itemProp="image" content="https://afoimichail.com//mainSitePage.jpg"/>

                                <meta property="og:url"
                                      content={`https://afoimichail.com/${window.location.hash}`}/>
                                <meta property="og:type" content="website"/>
                                <meta property="og:title" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>
                                <meta property="og:description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα"/>
                                <meta property="og:image"
                                      content="https://afoimichail.com/mainSitePage.jpg"/>

                            </Helmet>
                            <PageTitle title=" Όροι χρήσης – Πολιτική Προστασίας Προσωπικών Δεδομένων"/>
                        </div>
                    </Container>
                </div>


                <div className="content-wrapper">

                    <Row>

                        <Col lg={8} style={{textAlign: 'justify', margin: '15px', fontSize: '14px'}}>
                            <div className="content">
                                <p>Όροι χρήσης – Πολιτική Προστασίας Προσωπικών Δεδομένων</p>


                                <p>Ορισμοί : Όπου "ιστοσελίδα", εννοείται η παρούσα ιστοσελίδα (portal). </p>
                                <p>Όπου " στοιχεία"
                                    εννοούνται όλα τα δεδομένα που εμπεριέχονται στην ιστοσελίδα, όπως ενδεικτικά και
                                    όχι
                                    περιοριστικά, κάθε πίνακας, διάγραμμα, πληροφορία, αναφορά, ανάλυση, κείμενο,
                                    φωτογραφία, ως και οποιοδήποτε άλλο στοιχείο συμπεριλαμβανομένων ιδεών, εμπεριέχεται
                                    στην ιστοσελίδα. </p>
                                <p>Όπου " χρήστης " εννοείται κάθε επισκέπτης της ιστοσελίδας. </p>
                                <p>Όπου
                                    "χρήση" εννοείται η πρόσβαση, μελέτη, συμβουλή, αποθήκευση ή άλλη εγγραφή στη μνήμη
                                    ή σε
                                    άλλο μαγνητικό ή μη μέσο, εγκατάσταση, προβολή καθ' οιονδήποτε τρόπο, μηχανικό ή μη,
                                    συμπεριλαμβανομένης και της εκτύπωσης, των Στοιχείων της ιστοσελίδας. </p>
                                <p>Όπου " δικαιούχος"
                                    εννοείται η εταιρεία "https://afoimichail.com/#/", δημιουργός της Ιστοσελίδας και
                                    όλων των
                                    σε αυτήν περιεχομένων Στοιχείων, ή νόμιμος χρήστης όσων από τα Στοιχεία δεν
                                    αποτελούν
                                    πρωτότυπα πνευματικά δημιουργήματά της. </p>

                                <p>Παρακαλούμε διαβάστε προσεχτικά τα παρακάτω
                                    στοιχεία τα οποία αποτελούν όρους και προϋποθέσεις διεθνώς αναγνωρισμένους, για την
                                    πρόσβαση και χρήση των ιστοσελίδων. Η χρήση όλων των στοιχείων γίνεται από τον
                                    χρήστη
                                    της ιστοσελίδας, δωρεάν και χωρίς καμία απολύτως άμεση ή έμμεση επιβάρυνση ή
                                    υποχρέωση,
                                    διέπεται ωστόσο από τους ακόλουθους όρους, κανόνες και προϋποθέσεις: Τόσο στους
                                    παρόντες
                                    Όρους, όσο και στην Πολιτική Προστασίας Προσωπικών Δεδομένων και στην Πολιτική που
                                    αφορά
                                    στη Χρήση Cookies οι οποίες αποτελούν αναπόσπαστο τμήμα του παρόντος, μπορείτε να
                                    βρείτε
                                    περισσότερες πληροφορίες σχετικά με τις πολιτικές μας. </p>
                                <p>Η χρήση της ιστοσελίδας μας
                                    συνεπάγεται την πλήρη και ανεπιφύλακτη αποδοχή των παρόντων Όρων Χρήσης, οι οποίοι
                                    αφορούν στο σύνολο του περιεχομένου του. Η δικαιούχος διατηρεί το δικαίωμα να
                                    τροποποιεί
                                    και να αναπροσαρμόζει οποτεδήποτε και χωρίς προειδοποίηση τους παρόντες Όρους
                                    Χρήσης,
                                    ενώ οι εκάστοτε αλλαγές τίθενται σε ισχύ από τη δημοσίευση τους στην ιστοσελίδα μας.
                                    Μετά από την δημοσίευση των τροποποιήσεων, η χρήση της ιστοσελίδας μας συνεπάγεται
                                    την
                                    αποδοχή των εν λόγω τροποποιήσεων Οι ήδη υπάρχουσες καθώς και οποιεσδήποτε νέες
                                    υπηρεσίες συμπεριληφθούν στην ιστοσελίδα, υπόκεινται στους παρόντες όρους χρήσης,
                                    εκτός
                                    αν ρητά προβλέπεται το αντίθετο. Η ιστοσελίδα διατηρεί το δικαίωμα να τροποποιεί
                                    ή/και
                                    να διακόπτει προσωρινά ή μόνιμα μέρος ή το σύνολο των υπηρεσιών του με ή χωρίς
                                    προειδοποίηση προς τους επισκέπτες/χρήστες. </p>
                                <p>1. Ιδιοκτησία και πνευματικά δικαιώματα
                                    Κάθε
                                    δικαίωμα, ωφέλεια και αξίωση, από και επί των στοιχείων, ανήκουν αποκλειστικά στην
                                    δικαιούχο ή/και στους νόμιμα αναγραφόμενους συνεργάτες, εκτός και αν ρητά
                                    αναγράφεται
                                    διαφορετικά και προστατεύονται από τους ελληνικούς, κοινοτικούς και διεθνείς νόμους
                                    περί
                                    εμπορικών σημάτων και βιομηχανικής και πνευματικής ιδιοκτησίας, καθώς επίσης η
                                    παρουσίαση των παραπάνω στην ιστοσελίδα σε καμία περίπτωση δεν θα πρέπει να
                                    εκλαμβάνεται
                                    ως μεταβίβαση ή εκχώρηση αδείας ή δικαιώματος χρήσης τους. . Η δικαιούχος διατηρεί
                                    οποιαδήποτε στιγμή και σε κάθε περίπτωση τα πνευματικά, ως και κάθε άλλης φύσης,
                                    δικαιώματά της στα στοιχεία αυτά, καθώς και σε όλα τα μετέπειτα αντίγραφά τους, σε
                                    οποιαδήποτε μορφή και αν ευρίσκονται. Επίσης, ο δικτυακός μας τόπος, διατηρεί το
                                    δικαίωμα διενέργειας, ψηφοφορίας, gallops, ανάλυσης και σχολιασμού πάνω σε πολιτικά,
                                    κοινωνικά, και οικονομικά ζητήματα. Κάθε συμπέρασμα, εκτίμηση, πόρισμα, μιας τέτοιας
                                    ψηφοφορίας, αποτελούν πνευματική ιδιοκτησία του δικτυακού μας τόπου. Τα λοιπά
                                    στοιχεία
                                    που φέρουν τα σήματα των αντίστοιχων οργανισμών, εταιριών, συνεργατών φορέων,
                                    ενώσεων ή
                                    εκδόσεων, αποτελούν δική τους πνευματική και βιομηχανική ιδιοκτησία και συνεπώς οι
                                    φορείς αυτοί φέρουν τη σχετική ευθύνη .</p>
                                <p>2. Άδεια χρήσης Η άδεια να χρησιμοποιεί ο
                                    χρήστης τα στοιχεία είναι αυστηρά προσωπική και αποκλειστική, και διέπεται από τους
                                    όρους που παρατίθενται στην παρούσα. Επιτρέπεται μόνο η προσωπική χρήση και χωρίς
                                    απαλοιφή της ένδειξης προέλευσης των στοιχείων και σε καμία περίπτωση δεν
                                    επιτρέπεται να
                                    εκμεταλλευθεί κάποιος για οποιονδήποτε σκοπό πλην της προσωπικής του χρήσης καθ'
                                    οιονδήποτε τρόπο (ηλεκτρονικό, μηχανικό ή άλλο) τα στοιχεία, όπως ενδεικτικά και όχι
                                    περιοριστικά να τα αναδημοσιεύσει ολικά, μερικά, περιληπτικά, διασκευάζοντας ή
                                    παραφράζοντάς τα, και να δημιουργεί παράγωγα έργα τα οποία θα βασίζονται ολικά ή
                                    μερικά
                                    σε αυτά, με τρόπο διαφορετικό από αυτόν που ρητά καθορίζεται εδώ ή/και από τη
                                    νομοθεσία,
                                    χωρίς την προηγούμενη ρητή άδεια της δικαιούχου. </p>
                                <p>3. Περιορισμός ευθύνης Τα στοιχεία
                                    έχουν καθαρά συμβουλευτικό και ενημερωτικό χαρακτήρα και σκοπό, δεν προτρέπουν σε
                                    οποιαδήποτε περίπτωση σε επιχείρηση ή μη συγκεκριμένων πράξεων, ανανεώνονται δε και
                                    τροποποιούνται συνεχώς. Η δικαιούχος αναλαμβάνει τη συλλογή, επεξεργασία και διανομή
                                    του
                                    περιεχομένου τους, χωρίς όμως σε καμιά περίπτωση να εγγυάται την αρτιότητα, την
                                    πληρότητα, την επάρκεια και γενικά την καταλληλότητα αυτών και την απουσία
                                    ενδεχομένων
                                    λαθών, πολύ δε περισσότερο, λόγω του ιδιαιτέρως μεγάλου όγκου τους, καθώς και της
                                    συμμετοχής και τρίτων φορέων (φυσικών ή νομικών προσώπων) την πρωτογενή παραγωγή και
                                    συλλογή τους. Η δικαιούχος των στοιχείων δεν έχει σε καμία περίπτωση ευθύνη για
                                    αποζημίωση ή και επανόρθωση της οποιασδήποτε άμεσης ή έμμεσης απώλειας και θετικής ή
                                    αποθετικής ζημίας, ακόμη και μελλοντικής. Οι πληροφορίες που περιέχονται στον
                                    παρόντα
                                    διαδικτυακό τόπο έχουν ελεγχθεί και συγκεντρωθεί με τη δέουσα προσοχή. Ωστόσο, παρά
                                    την
                                    προσπάθεια της δικαιούχου προκειμένου το περιεχόμενο και οι πληροφορίες που
                                    περιλαμβάνονται στον παρόντα διαδικτυακό τόπο να είναι πλήρεις, έγκυρες και
                                    ακριβείς,
                                    λόγω της φύσης και του όγκου του διαδικτύου, υπό οποιεσδήποτε συνθήκες,
                                    συμπεριλαμβανομένης και της περίπτωσης της αμέλειας, η δικαιούχος δεν εγγυάται και
                                    δεν
                                    ευθύνεται για την ακρίβεια των στοιχείων αυτών. Η χρήση της ιστοσελίδας από κάθε
                                    χρήστη
                                    γίνεται με αποκλειστική του ευθύνη και η δικαιούχος δεν ευθύνεται για οποιασδήποτε
                                    μορφής ζημία υποστούν οι χρήστες των σελίδων, υπηρεσιών, επιλογών,περιεχομένων και
                                    στοιχείων του site στις οποίες προβαίνουν με δική τους πρωτοβουλία. Το περιεχόμενο
                                    του
                                    διαδικτυακού τόπου παρέχεται στους χρήστες αυτούσιο, χωρίς οποιαδήποτε μορφή
                                    εγγύησης
                                    εκπεφρασμένης ή συνεπαγόμενης με οποιοδήποτε τρόπο και, ως εκ τούτου,στο μέγιστο
                                    βαθμό
                                    και σύμφωνα με το νόμο, η δικαιούχος δεν δεσμεύεται, δεν εγγυάται και δεν
                                    αναλαμβάνει
                                    καμία ευθύνη ή υποχρέωση αναφορικά με την ασφάλεια αυτού, αρνούμενη όλες τις
                                    εγγυήσεις
                                    εκπεφρασμένες ή και συνεπαγόμενες, συμπεριλαμβανομένων, όχι όμως περιοριζόμενων σε
                                    αυτό,
                                    αυτών, οι οποίες συνεπάγονται την εμπορευσιμότητα και την καταλληλότητα για ένα
                                    συγκεκριμένο σκοπό. Η δικαιούχος αν και έχει λάβει, στο μέτρο του δυνατού, όλα τα
                                    απαραίτητα μέτρα ασφαλείας για την προστασία του διαδικτυακού τόπου από κακόβουλο
                                    λογισμικό/ιούς ή/και οποιουδήποτε άλλου είδους βλαβερά ή καταστροφικά αρχεία ή/και
                                    προγράμματα σχεδιασμένα να παρεμβληθούν, καταστρέψουν ή περιορίσουν τη λειτουργία
                                    οποιουδήποτε λογισμικού ή τηλεπικοινωνιακού εξοπλισμού, δεν μπορεί να εγγυηθεί ότι ο
                                    δικτυακός τόπος δεν θα προσβληθεί ποτέ από τέτοια στοιχεία. Συνεπώς η ακεραιότητα
                                    και
                                    διαφύλαξη των λογισμικών συστημάτων και του εξοπλισμού κάθε χρήστη εναπόκειται στη
                                    δική
                                    του επιμέλεια, η δε δικαιούχος δε θα φέρει καμία ευθύνη σε περίπτωση προσβολής του
                                    ηλεκτρονικού εξοπλισμού (software και hardware) των χρηστών από “ιούς” και άλλα
                                    βλαβερά
                                    αρχεία όπως αυτά εκτίθενται παραπάνω. Η δικαιούχος και οι συνδεδεμένες με αυτήν
                                    εταιρείες ή τυχόν άλλα μέρη που εμπλέκονται στη δημιουργία και παράδοση του
                                    περιεχομένου
                                    του παρόντος διαδικτυακού τόπου ή εν γένει στη λειτουργία αυτού σε καμία περίπτωση
                                    δεν
                                    ευθύνονται για οποιεσδήποτε ζημίες, άμεσες ή έμμεσες, επιγενόμενες ή παρεπόμενες,
                                    θετικές ή αποθετικές, που ενδέχεται να προκύψουν αναφορικά με τον παρόντα
                                    διαδικτυακό
                                    τόπο και αφορούν στη χρήση ή αδυναμία χρήσης αυτού, καθώς και σε κάθε παράλειψη,
                                    ανακρίβεια, καθυστέρηση ή αποτυχία λειτουργίας, υποκλοπή ηλεκτρονικών επικοινωνιών
                                    από
                                    τρίτους ή από προγράμματα υπολογιστών που χρησιμοποιούνται για τη μετάδοση ιών και
                                    σε
                                    τυχόν άλλα σφάλματα ή ελαττώματα επ’ αυτού. Η δικαιούχος δεν εγγυάται ότι οι
                                    σελίδες, οι
                                    υπηρεσίες, οι επιλογές και τα περιεχόμενα θα παρέχονται χωρίς διακοπή, χωρίς
                                    σφάλματα
                                    και ότι τα λάθη θα διορθώνονται. Επίσης, δεν εγγυάται ότι το ίδιο ή οποιοδήποτε άλλο
                                    συγγενικό site ή οι servers μέσω των οποίων αυτά τίθενται στη διάθεσή των
                                    χρηστών/μελών,
                                    δεν περιέχουν ιούς ή άλλα επιζήμια συστατικά. Δεν εγγυάται σε καμία περίπτωση την
                                    ορθότητα, την πληρότητα ή και διαθεσιμότητα των περιεχομένων, σελίδων, υπηρεσιών,
                                    επιλογών ή τα αποτελέσματά τους. Το κόστος των ενδεχόμενων διορθώσεων ή
                                    εξυπηρετήσεων,
                                    το αναλαμβάνουν οι χρήστες και σε καμία περίπτωση η δικαιούχος. </p>
                                <p>4. Διαφημίσεις Η
                                    ιστοσελίδα περιέχει διαφημίσεις, καθώς και προβολή άλλου υλικού με διαφημιστικό
                                    περιεχόμενο, σκοπό και χαρακτήρα. Η δικαιούχος δεν φέρει καμία ευθύνη έναντι των
                                    χρηστών, ως και οιωνδήποτε τρίτων που ήθελε θεωρηθεί ότι θίγονται, για οποιαδήποτε
                                    παράνομη πράξη ή παράλειψη, ανακρίβεια ή αδυναμία συμμόρφωσης προς τους νόμους και
                                    κανονισμούς οποιασδήποτε χώρας ή της Ευρωπαϊκής Ένωσης σε σχέση με το περιεχόμενο
                                    των
                                    διαφημίσεων. Η δικαιούχος δεν υποχρεούται να εξετάσει και δεν εξετάζει τη νομιμότητα
                                    ή
                                    όχι του διαφημιστικού υλικού που προβάλλεται στην ιστοσελίδα (πέραν των προφανών
                                    περιπτώσεων προσβολής εννόμων δικαιωμάτων που θα γίνονταν αντιληπτές και από τον
                                    μέσο
                                    συνετό πολίτη) και ως εκ τούτου δεν δύναται να της καταλογιστεί οποιασδήποτε μορφής
                                    ευθύνη. Η ευθύνη αυτή βαρύνει τους διαφημιζόμενους, χορηγούς ή και δημιουργούς του
                                    προβαλλόμενου διαφημιστικού αυτού υλικού, οι οποίοι φέρουν επίσης την ευθύνη
                                    απέναντι
                                    στον χρήστη/καταναλωτή για τα προϊόντα/υπηρεσίες τους καθώς και για τις απαραίτητες
                                    εγγυήσεις, αδειοδοτήσεις, εγγραφές σε αρχές/μητρώα και σημάνσεις των
                                    προϊόντων/υπηρεσιών
                                    τους. Η δικαιούχος δεν έχει καμία ευθύνη για την επικοινωνία των χρηστών με τους
                                    τρίτους
                                    παροχείς υπηρεσιών που διαφημίζονται και για την οποιαδήποτε τυχόν εμπορική
                                    συναλλαγή
                                    που μπορεί να προκύψει από τη μεταξύ τους σχέση. </p>
                                <p>5. Πληροφορίες που προέρχονται από
                                    τρίτους – Σύνδεση με διαδικτυακούς τόπους τρίτων Τυχόν πληροφορίες που προέρχονται
                                    από
                                    τρίτα μέρη συνιστούν έκφραση προσωπικών απόψεων των προσώπων αυτών. Η δικαιούχος δεν
                                    φέρει καμία ευθύνη για τέτοιου είδους πληροφορίες. Η ιστοσελίδα παρέχει απευθείας
                                    πρόσβαση σε άλλες ιστοσελίδες ή/και ηλεκτρονικές διευθύνσεις, οι οποίες ανήκουν σε
                                    τρίτα
                                    μέρη και ως εκ τούτου δεν ευρίσκονται υπό τη διαχείριση της δικαιούχου και δεν
                                    υφίσταται
                                    δυνατότητα παρέμβασης εκ μέρους της Εταιρείας στο περιεχόμενο αυτών. Οι πάροχοι των
                                    εξωτερικών αυτών ιστοσελίδων φέρουν την πλήρη αστική και ποινική ευθύνη για την
                                    ασφάλεια
                                    και την εγκυρότητα του περιεχομένου και των πληροφοριών που εμπεριέχονται σε αυτούς.
                                    Η
                                    δικαιούχος δεν εγγυάται τη διαθεσιμότητά τους και δεν ευθύνεται για το περιεχόμενο,
                                    την
                                    ορθότητα, νομιμότητα, πληρότητα και ακρίβεια των πληροφοριών, την πολιτική
                                    προστασίας
                                    προσωπικών δεδομένων, συμπεριλαμβανομένου και του ενσωματώσιμου προγράμματος
                                    αναπαραγωγής οπτικού υλικού υπηρεσιών όπως το Youtube, Facebook ούτε για την
                                    ποιότητα και
                                    τις
                                    ιδιότητες των προϊόντων ή υπηρεσιών, που διατίθενται από τους διαδικτυακούς τόπους
                                    τρίτων. Ως εκ τούτου, η δικαιούχος δεν φέρει ουδεμία ευθύνη για το περιεχόμενο
                                    διαδικτυακών τόπων τρίτων που συνδέονται με την παρούσα ιστοσελίδα. Η σύνδεση των
                                    χρηστών στους εξωτερικούς αυτούς διαδικτυακούς τόπους γίνεται με δική τους ευθύνη
                                    και σε
                                    κάθε περίπτωση, οι χρήστες οφείλουν να απευθύνονται απευθείας στους παρόχους των
                                    ιστοτόπων αυτών οι οποίοι φέρουν και τη σχετική ευθύνη για οτιδήποτε τυχόν προκύψει
                                    από
                                    την επίσκεψη και χρήση τους. Η ιστοσελίδα σε καμία περίπτωση δεν πρέπει να θεωρηθεί
                                    ότι
                                    ενστερνίζεται ή αποδέχεται το περιεχόμενο ή τις υπηρεσίες των web sites και των
                                    σελίδων
                                    στα οποία παραπέμπει ή ότι συνδέεται με αυτά κατά οποιονδήποτε τρόπο.</p>
                                <p>6.
                                    Δημοσίευση περιεχομένων από χρήστες Το <a href="https://afoimichail.com/#/"
                                                                              rel="noopener noreferrer">Αφοί
                                        Μιχαήλ</a> παρέχει στους χρήστες τη δυνατότητα
                                    να
                                    δημοσιεύουν περιεχόμενο όπως ενδεικτικά κείμενα, εικόνες και βίντεο σε διάφορα
                                    σημεία
                                    του δικτυακού τόπου. Το περιεχόμενο αυτό είναι και παραμένει στην αποκλειστική
                                    ευθύνη
                                    του χρήστη που το δημοσίευσε ο οποίος και παραμένει ο μόνος υπεύθυνος για αυτό. Η
                                    χρήση
                                    της ιστοσελίδας από τους χρήστες οφείλει να κινείται εντός του πλαισίου που θέτει ο
                                    νόμος, η καλή πίστη και τα χρηστά ήθη και να γίνεται με τρόπο που να μην περιορίζει
                                    ή
                                    εμποδίζει την χρήση του από τρίτους. Οι χρήστες της ιστοσελίδας οφείλουν να
                                    συμμορφώνονται με τους κανόνες και τις διατάξεις του ελληνικού, της Ευρωπαϊκής
                                    Ένωσης
                                    και τις σχετικές διεθνείς συνθήκες και να απέχουν από κάθε παράνομη και καταχρηστική
                                    συμπεριφορά που μπορεί να έχει επιπτώσεις σε άλλους χρήστες και να προκαλέσει
                                    βλάβη/δυσλειτουργία της ιστοσελίδας. Σε περίπτωση παράνομης ή αντίθετης στους
                                    παρόντες
                                    Όρους και το εκάστοτε ισχύον νομοθετικό πλαίσιο συμπεριφοράς, ο χρήστης υποχρεούται
                                    να
                                    αποζημιώσει την δικαιούχο για κάθε ζημία που η ίδια υποστεί από τις ως άνω
                                    ενέργειες. Οι
                                    χρήστες, προκειμένου να αποκτήσουν το δικαίωμα δημοσίευσης σχολίων και θεμάτων στο
                                    <a href="https://afoimichail.com/#/" rel="noopener noreferrer">Αφοί Μιχαήλ</a>, έχουν
                                    την δυνατότητα να εγγράφονται απευθείας με το e-mail τους ή μέσω των
                                    λογαριασμών τους στα κοινωνικά δίκτυα Facebook . Στην πρώτη περίπτωση, η
                                    εταιρεία δεν μεταβιβάζει τα στοιχεία του χρήστη σε κανέναν άλλον και πουθενά αλλού.
                                    Στην
                                    δεύτερη, ισχύουν οι όροι που το κάθε κοινωνικό δίκτυο θέτει στους χρήστες του,
                                    προκειμένου να χρησιμοποιηθεί ως μεσάζων. Ο χρήστης κατανοεί και αποδέχεται ότι οι
                                    πληροφορίες αυτές δημοσιεύονται χωρίς προηγούμενο έλεγχο από το site και ότι ο
                                    διαχειριστής του δικτυακού τόπου διατηρεί το δικαίωμα να απομακρύνει εκ των υστέρων
                                    οποιοδήποτε δημοσιευμένο από τον χρήστη περιεχόμενο. Ο χρήστης κατανοεί και
                                    αποδέχεται
                                    ότι χρησιμοποιώντας τις σελίδες και τις υπηρεσίες του δικτυακού τόπου μπορεί να
                                    εκτεθεί
                                    σε περιεχόμενο προσβλητικό, ανήθικο ή παράνομο. Επίσης κατανοεί και αποδέχεταi ότι ο
                                    δικτυακός τόπος δεν μπορεί να θεωρηθεί ότι ενστερνίζεται ή ότι είναι υπεύθυνος για
                                    το
                                    περιεχόμενο που έχει δημοσιευτεί από κάποιον χρήστη. Οι χρήστες κατανοούν και
                                    συμφωνούν
                                    ότι έχουν τις παρακάτω υποχρεώσεις σχετικά με το περιεχόμενο που δημοσιεύουν στο
                                    site: -
                                    Να μην είναι είναι παράνομο, προσβλητικό, υβριστικό, πορνογραφικό δυσφημιστικό,
                                    ρατσιστικό. - Να μη στοχεύει να διαβάλει ή να συκοφαντήσει πρόσωπα, εταιρείες ή
                                    οργανισμούς. - Να μην παρεμποδίζει τη σωστή λειτουργία του δικτυακού τόπου. - Να μην
                                    παραβιάζει την προσωπικότητα και τα προσωπικά δεδομένα τρίτων. - Να μην παραβιάζει
                                    πνευματικά δικαιώματα. - Να μην συνιστά ποινικό αδίκημα με οποιοδήποτε τρόπο. - Να
                                    είναι
                                    σύμφωνο με την υπάρχουσα εθνική, ευρωπαϊκή και διεθνή νομοθεσία. Σε περίπτωση που
                                    κάποιος χρήστης αντιληφθεί περιεχόμενο αντίθετο με τα παραπάνω, το <a
                                        href="https://afoimichail.com/#/" rel="noopener noreferrer">Αφοί Μιχαήλ</a> τον
                                    ενθαρύνει
                                    να επικοινωνήσει μαζί μας μέσω email, με κάθε τρόπο για να το αναφέρει. Το
                                    περιεχόμενο το οποίο θα υποδειχθεί από τον χρήστη θα ελεγχθεί και αν κριθεί αναγκαίο
                                    θα
                                    απομακρυνθεί. Η αξιολόγηση του περιεχομένου που έχει δημοσιευτεί από άλλους χρήστες
                                    καθώς και η χρήση αυτού του περιεχομένου σαν πληροφορία είναι αποκλειστική
                                    υπευθυνότητα
                                    του χρήστη και σε καμία περίπτωση δεν πρέπει να θεωρηθεί το site ως υπεύθυνο για την
                                    ορθότητα και την χρησιμότητά του. 8. Ασφάλεια Το <a href="https://afoimichail.com/#/"
                                                                                        rel="noopener noreferrer">Αφοί
                                        Μιχαήλ</a> λαμβάνει κάθε δυνατό μέτρο
                                    για
                                    να διασφαλίσει τις υπηρεσίες που προσφέρει αλλά δεδομένης της φύσης του Διαδικτύου
                                    δεν
                                    ευθύνεται για οποιασδήποτε μορφής ζημιά υποστεί ο χρήστης από την επίσκεψη των
                                    σελίδων
                                    και την χρήση των υπηρεσιών του.</p>

                                <p> 8. Προσωπικά δεδομένα Η δικαιούχος δεσμεύεται ότι
                                    θα
                                    χρησιμοποιεί τυχόν προσωπικά δεδομένα που οικειοθελώς οι χρήστες θα χορηγούν σε
                                    αυτήν
                                    κατά τρόπο σύννομο με την ελληνική και κοινοτική νομοθεσία και με σκοπό τη βελτίωση
                                    των
                                    υπηρεσιών που παρέχει στους χρήστες ή προκειμένου να διαπιστώσει τις ανάγκες,
                                    επιθυμίες
                                    και προσδοκίες αυτών.</p>
                                <p>9. Μεταβατικές διατάξεις Η μη άσκηση από την δικαιούχο
                                    οποιουδήποτε δικαιώματος εξουσίας ή δικαιώματος αποζημιώσεως, που απορρέουν από το
                                    νόμο
                                    δεν ισοδυναμεί σε καμία περίπτωση με παραίτηση από το δικαίωμα αυτό. Σε περίπτωση
                                    που
                                    μια ή περισσότερες από τις διατάξεις που περιέχονται στους παρόντες Όρους Χρήσης
                                    κριθεί
                                    αντίθετος προς τον νόμο, και ως εκ τούτου άκυρος ή ακυρώσιμος, παύει να ισχύει χωρίς
                                    να
                                    θίγεται η ισχύς των υπόλοιπων όρων. Οι παρόντες όροι καθώς και κάθε τροποποίηση ή
                                    προσθήκη αυτών, διέπονται και ερμηνεύονται από το ελληνικό δίκαιο, το δίκαιο της
                                    Ευρωπαϊκής Ένωσης και τις σχετικές διεθνείς συνθήκες, αρμόδια δε για την επίλυση
                                    κάθε
                                    διαφοράς θα είναι τα καθ' ύλην ελληνικά δικαστήρια . Η δικαιούχος, ωστόσο, έχει το
                                    δικαίωμα να παραιτηθεί από την παρούσα διάταξη και να εφαρμόσει ή και να ερμηνεύσει
                                    τους
                                    παρόντες όρους σύμφωνα με το δίκαιο της χώρας του χρήστη, και να υπαγάγει τις
                                    οποιεσδήποτε διαφορές ή/και στη δικαιοδοσία της χώρας του χρήστη. Πηγή: <a
                                        href="https://afoimichail.com/#/" rel="noopener noreferrer">Αφοί Μιχαήλ</a>
                                </p>
                                <p>ΠΡΟΣΟΧΗ Συνέπειες: Η χρήση, αναδημοσίευση, κυκλοφορία κλπ ή η με οποιονδήποτε
                                    άλλο
                                    τρόπο, ακόμη και μελλοντικό, εκμετάλλευση των στοιχείων χωρίς την άδεια της
                                    δικαιούχου επισύρει σωρεία αστικών και ποινικών κυρώσεων, ιδίως εκ του νόμου
                                    2121/1993 "περί πνευματικής ιδιοκτησίας", του Π.Κ., και του άρθρου 60 του Α.Κ.,
                                    καθώς και άλλων ειδικότερων νόμων. Πηγή: https://afoimichail.com/#/</p>

                            </div>
                        </Col>

                        <Col>

                            <br/><br/>

                            <SocialFacebookPage/>

                        </Col>
                    </Row>

                </div>
            </div>
        )
    }
}

export default withRouter(PolicyTerms);
