/*
* Post Detail Page
*/
import React, {Component} from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {Row} from 'reactstrap';
import {Helmet} from "react-helmet-async";
import {FacebookProvider, Comments} from 'react-facebook';
import {FacebookShareButton, FacebookIcon,} from "react-share";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};
const productslider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1
};

class PostDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            newImage: props.product.pictures[0],
            url: '',
            title: '',
            description: '',
            image: ''
        };
        this.refreshFacebookPage = this.refreshFacebookPage.bind(this);
        this.toggleSharePopup = this.toggleSharePopup.bind(this);
        this.toggleLike = this.toggleLike.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.refreshFacebookPage();

    }

    componentDidUpdate() {
        this.refreshFacebookPage();
    }

    refreshFacebookPage() {
        window.FB.init({
            appId: '770753807046943',
            status: true,
            xfbml: true,
            version: 'v2.7'
        });
        window.FB.XFBML.parse();
    }

    changePreviewImage(image) {
        this.setState({
            newImage: image,
            tabid: 1
        });
    }


    onSharePopup({product}) {

        this.toggleSharePopup(product);
    }

    toggleSharePopup(product) {

        let id = product.id;
        let url = "https://afoimichail.com/#/properties/apartment/" + id + "/";
        let category = product.category;
        let name = product.name;
        let title = product.category + " " + product.name;
        let description = product.category + " " + product.name;

        let image = "https://afoimichail.com"+require(`../../assets/real-estate/${this.state.newImage}`);

        this.setState({
            url: url,
            title: `${category} - ${name}`,
            description: `${category} - ${name}`,
            image: image
        });


        window.FB.ui(
            {
                appId: '770753807046943',
                status: true,
                xfbml: true,
                version: 'v2.7',
                method: 'share_open_graph',
                action_type: 'og.shares',
                action_properties: JSON.stringify({
                    object: {
                        'og:url': url,
                        'og:title': title,
                        'og:description': description,
                        'og:image:url': image,
                        'og:image:width': '1920',
                        'og:image:height': '1200',
                    }
                }),
            },
            // callback
            function (response) {
                // if (response && !response.error_message) {
                //     alert('Posting completed.' + url);
                // } else {
                //     alert('Error while posting.'+ url);
                // }
            }
        );

        window.FB.XFBML.parse();


    }

    onLike({product}) {
        this.toggleLike({product});
    }

    toggleLike(product) {

        let id = product.id;
        let url = "https://afoimichail.com/#/properties/apartment/" + id + "/";
        let category = product.category;
        let name = product.name;
        let image = "https://afoimichail.com"+require(`../../assets/real-estate/${this.state.newImage}`);

        this.setState({
            url: url,
            title: `${category} - ${name}`,
            description: `${category} - ${name}`,
            image: image
        });

        window.FB.ui({
            appId: '770753807046943',
            status: true,
            xfbml: true,
            version: 'v2.7',
            method: 'share_open_graph',
            action_type: 'og.likes',
            action_properties: JSON.stringify({
                object: this.state.url
            })
        }, function (response) {
            // if (!response) {
            //     console.log('User did not share the page.');
            // } else {
            //     console.log('User shared the page!');
            // }
        });

        window.FB.XFBML.parse();
    }


    render() {
        const {photoIndex, isOpen} = this.state;
        const {product} = this.props;
        const images = [];
        // eslint-disable-next-line
        {
            product.pictures.map((pic) => images.push(require(`../../assets/real-estate/${pic}`)))
        }

        let rat = [];
        let rating = product.rating;
        let i = 1;
        while (i <= 5) {
            if (i <= rating) {
                rat.push(<i className="fa fa-star"/>);
            } else {
                rat.push(<i className="fa fa-star-o"/>);
            }
            i += 1;
        }
        ;


        return (
            <section>
                <div className="product-content-top single-product">
                    <Row>

                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>{product.category} {product.name} </title>
                            <meta name="description"
                                  content={`${product.category} 
                                              - ${product.name} 
                                              - ${product.totalDescription} 
                                              - ${product.status} -  ${product.areaApartment}`}/>

                            <meta itemProp="name" content={`${product.category} ${product.name}`}/>
                            <meta itemProp="description" content={`${product.category} 
                                              - ${product.name} 
                                              - ${product.totalDescription} 
                                              - ${product.status} -  ${product.areaApartment}`}/>

                            <meta itemProp="image" content={`https://afoimichail.com${require(`../../assets/real-estate/${this.state.newImage}`)}`}/>
                            <meta property="og:url"
                                  content={`https://afoimichail.com/${window.location.hash}`}/>
                            <meta property="og:type" content="website"/>
                            <meta property="og:title" content={`${product.category} ${product.name}`}/>
                            <meta property="og:description" content={`${product.category} 
                                              - ${product.name} 
                                              - ${product.totalDescription} 
                                              - ${product.status} -  ${product.areaApartment}`}/>
                            <meta property="og:image"
                                  content={`https://afoimichail.com${require(`../../assets/real-estate/${this.state.newImage}`)}`} />
                            <meta property="og:image:secure_url"
                                  content={`https://afoimichail.com${require(`../../assets/real-estate/${this.state.newImage}`)}`}/>
                            <meta property="fb:app_id"
                                  content="770753807046943"/>

                        </Helmet>

                        <div className="product-top-left col-xl-5 col-md-6">
                            <div className="product-top-left-inner">
                                <div className="ciyashop-product-images">
                                    <div
                                        className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
                                        <div
                                            className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
                                            <Slider {...settings}
                                                    className="ciyashop-product-gallery__wrapper popup-gallery">
                                                <div className="ciyashop-product-gallery__image">
                                                    <img
                                                        src={require(`../../assets/real-estate/${this.state.newImage}`)}
                                                        alt="" className="img-fluid"
                                                        style={{height: '380px', width: '100%'}}/>
                                                </div>
                                            </Slider>
                                            <div className="ciyashop-product-gallery_buttons_wrapper">
                                                <div
                                                    className="ciyashop-product-gallery_button ciyashop-product-gallery_button-zoom popup-gallery"
                                                    onClick={() => this.setState({isOpen: true})}>
                                                    <Link to="#" className="ciyashop-product-gallery_button-link-zoom">
                                                        <i className="fa fa-arrows-alt"/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ciyashop-product-thumbnails">
                                            <Slider {...productslider} className="ciyashop-product-thumbnails__wrapper">
                                                {product.pictures.map((pictureimage, index) =>
                                                    <div className="ciyashop-product-thumbnail__image">
                                                        <Link onMouseOver={() => this.changePreviewImage(pictureimage)}>
                                                            <img
                                                                src={require(`../../assets/real-estate/${pictureimage}`)}
                                                                alt="" className="img-fluid"/>
                                                        </Link>
                                                    </div>
                                                )}
                                            </Slider>
                                        </div>
                                        <div className="clearfix"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="product-top-right col-xl-7 col-md-6">
                            <div className="product-top-right-inner">
                                <div className="summary entry-summary">


                                    <h1 className="product_title entry-title"
                                        style={{float: "center"}}
                                        onClick={() => this.onSharePopup({product})}>{product.name}</h1>


                                    <div className="product-details__short-description">
                                        <div className="pdp-about-details-txt pdp-about-details-equit"
                                             style={{textAlign: "justify"}}>

                                            {product.description}

                                        </div>
                                    </div>
                                    <div className="product_meta" style={{textAlign: "justify"}}>

                                        <span className="posted_in"> <label>Τύπος:</label>{product.category}  </span>

                                        <span className="posted_in"> <label>Κατάσταση:</label>{product.status}  </span>

                                        <span className="posted_in"> <label>Εμβαδό:</label>{product.square}  </span>
                                        <span className="posted_in"> <label>Όροφος:</label>{product.floor}  </span>
                                        <span className="posted_in"> <label>Σύστημα Θέρμανσης:</label>{product.heating}  </span>

                                        <span className="brands"> <label><i
                                            className="fa fa-tags"/>&nbsp;Χαρακτηριστικά:</label> {product.tags.map((brand, index) =>
                                                <span itemProp="brand">
                                    <Link to="#" rel="tag">{brand}{index === product.tags.length - 1 ? '' : ','}</Link>
                                </span>
                                        )}


                        </span>

                                        <FacebookShareButton
                                            url={`https://afoimichail.com/${window.location.hash}`}
                                            quote={`${product.status} - ${product.name}`}
                                            hashtag={`https://afoimichail.com : ${product.status} - ${product.name}`}
                                        >
                                            <FacebookIcon logoFillColor="white"/>
                                        </FacebookShareButton>

                                        <FacebookProvider appId="770753807046943">
                                            <Comments href={`https://afoimichail.com/${window.location.hash}`}
                                                      numPosts="5"/>

                                        </FacebookProvider>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </Row>
                </div>
                <div>


                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => this.setState({isOpen: false})}
                            enableZoom={false}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % images.length,
                                })
                            }
                        />
                    )}
                </div>

            </section>


        )
    }
}

export default PostDetail;

