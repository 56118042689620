import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import navLinks from '../../NavLinks.js';
import {Row, Col, Container} from 'reactstrap';
import Loader from 'react-loader-spinner';
import {
    Navbar,
    NavbarToggler, Nav, NavItem, Collapse
} from 'reactstrap';
import {Helmet} from "react-helmet";


class Header extends Component {
    constructor(props) {

        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            timeout: true,
            modal: false,
            activeTab: '1',
            isOpen: false,
            classset: '',
            collapsed: true
        };
        this.toggle = this.toggle.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.refreshFacebookPage = this.refreshFacebookPage.bind(this);
    }


    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }


    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.refreshFacebookPage();
    }

    refreshFacebookPage() {
        window.FB.init({
            appId: '770753807046943',
            status: true,
            xfbml: true,
            version: 'v2.7'
        });
        window.FB.XFBML.parse();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;

        if (scrollTop > 100) {
            document.getElementById("site-header").setAttribute("class", "site-header header-style-menu-center is-sticky");
        } else {
            document.getElementById("site-header").setAttribute("class", "site-header header-style-menu-center");
        }
    }


    closeNavbar() {
        if (this.state.collapsed !== true) {
            this.toggleNavbar();
        }
    }


    render() {

        let pathnames = document.location.href;
        let pathArray = pathnames.split('/');
        let pageName = '/' + pathArray[pathArray.length - 1];
        if (this.state.timeout === true) {
            setTimeout(function () {
                this.setState({timeout: false});
            }.bind(this), 2000);  // wait 5 seconds, then reset to false
        }


        return (

            <div>
                <section id="topbar" className="d-none d-lg-block topbar-desktop-on topbar-mobile-off">

                    <Helmet>
                        <title>Η Εταιρία «Αφοί Μιχαήλ» </title>
                        <meta name="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα
                                          Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                        <meta itemProp="name" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>

                        <meta itemProp="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                        <meta itemProp="image" content="https://afoimichail.com//mainSitePage.jpg"/>

                        <meta property="og:url" content="https://afoimichail.com"/>
                        <meta property="og:type" content="website"/>
                        <meta property="og:title" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>
                        <meta property="og:description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>
                        <meta property="og:image"
                              content="https://afoimichail.com/mainSitePage.jpg"/>

                    </Helmet>

                    <div className="container clearfix">

                        <div className="contact-info float-left">
                            <i className="fa fa-envelope-o"/> <a
                            href="mailto:contact@example.com">michailafoi@gmail.com</a>
                            <i className="fa fa-phone"/> +3 697 778 9830
                        </div>

                        <div className="social-links float-right">

                            <div className="fb-share-button"
                                 data-href="https://www.facebook.com/afoimichail"
                                 data-layout="button_count"
                                 data-size="large">
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.facebook.com%2Fafoimichail&amp;src=sdkpreparse"
                                   className="fb-xfbml-parse-ignore">Κοινοποίηση</a>
                            </div>
                        </div>
                    </div>
                </section>


                <header className="site-header header-style-menu-center" id="site-header">
                    {this.state.timeout === false ?
                        <div>

                            <div className="header-main header-main-bg-color-default">
                                <div className="container-fluid">

                                    <Row>

                                        <Col lg={4}>
                                            <div id="logo-wrapper" className="pull-right">

                                                <h1 style={{marginLeft: '150px'}}><a href="https://afoimichail.com/"
                                                                                     style={{
                                                                                         textDecoration: 'none',
                                                                                         color: 'dodgerblue'
                                                                                     }}> Αφοι <span> Μιχαήλ</span></a>
                                                </h1>

                                                {/*<Link to="/">*/}
                                                {/*    <img className="img-fluid" src={logo} alt="logo"/>*/}
                                                {/*</Link>*/}
                                            </div>
                                            <div className="clearfix"/>
                                        </Col>
                                        <Col lg={8}>
                                            <div style={{marginRight: '100px'}}
                                                 className="row align-items-left justify-content-md-left">

                                                <div className="col" id="mainMenu">
                                                    <div className="header-nav header-nav-bg-color-default">
                                                        <div className="header-nav-wrapper">
                                                            <Container>
                                                                <Row>
                                                                    <div className="col-12">
                                                                        <div className="primary-nav">
                                                                            <div className="primary-nav-wrapper">
                                                                                <nav className="mega-menu">
                                                                                    <div className="menu-list-items">
                                                                                        <nav id="nav-menu-container">
                                                                                            <ul className="nav-menu">
                                                                                                <li className="menu-active">
                                                                                                    <Link
                                                                                                        className="nav-link"
                                                                                                        to="/"><i
                                                                                                        className="fa fa-home fa-sm">&nbsp;</i>ΑΡΧΙΚΗ</Link>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <Link
                                                                                                        className="nav-link"
                                                                                                        to="/about"><i
                                                                                                        className="fa fa-user fa-sm">&nbsp;</i>Η
                                                                                                        ΕΤΑΙΡΙΑ</Link>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <Link
                                                                                                        className="nav-link"
                                                                                                        to="/services"><i
                                                                                                        className="fa fa-cogs fa-sm">&nbsp;</i>ΥΠΗΡΕΣΙΕΣ</Link>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <Link
                                                                                                        className="nav-link"
                                                                                                        to="/properties"><i
                                                                                                        className="fa fa-building fa-sm">&nbsp;</i>ΑΚΙΝΗΤΑ</Link>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <Link
                                                                                                        className="nav-link"
                                                                                                        to="/contact"><i
                                                                                                        className="fa fa-envelope-o fa-sm">&nbsp;</i>ΕΠΙΚΟΙΝΩΝΙΑ</Link>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </nav>
                                                                                    </div>
                                                                                </nav>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            </Container>
                                                        </div>
                                                    </div>
                                                </div>


                                                <Navbar color="faded" light>

                                                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
                                                    <Collapse isOpen={!this.state.collapsed} navbar>
                                                        <Nav className="ml-auto" navbar>
                                                            {navLinks.map((navLink, index) => (
                                                                <li className={`nav-item ${(this.state.classset === navLink.menu_title) ? 'show' : ''}`}>
                                                                    {(navLink.type && navLink.type === 'subMenu') ?
                                                                        <Fragment>
                                                                            <ul className={(this.state.classset === navLink.menu_title)}>
                                                                                <li className={`nav-item  ${(pageName === navLink.path) ? 'active' : ''}`}
                                                                                    toggle={true}>
                                                                                    <Link className="nav-link"
                                                                                          onClick={() => this.closeNavbar()}
                                                                                          to={navLink.path}>{navLink.menu_title}</Link>
                                                                                </li>

                                                                            </ul>
                                                                        </Fragment>
                                                                        :
                                                                        <Fragment>
                                                                            <NavItem>
                                                                                <Link to={navLink.path}
                                                                                      className="nav-admin-link">{navLink.menu_title}</Link>
                                                                            </NavItem>

                                                                        </Fragment>
                                                                    }
                                                                </li>
                                                            ))}
                                                        </Nav>
                                                    </Collapse>
                                                </Navbar>

                                            </div>
                                        </Col>

                                    </Row>

                                </div>
                            </div>
                        </div>

                        :

                        <div id="preloader">

                            <Loader type="Circles" color="dodgerblue" height="80" width="80"/>

                        </div>
                    }
                </header>

            </div>
        )
            ;
    }
}

export default withRouter(Header);
