/**
 *  MainPageSlider
 */
import React, {Component} from 'react';
import AboutCompanyMainPage from "../AboutCompany/AboutCompany";
import ServicesMainPage from "../ServicesMainPage/ServicesMainPage";
import ContactMainPage from "../ContactMainPage/ContactMainPage";
import {withRouter} from "react-router-dom";
import MainPageSlider from "../../layouts/mainPageSlider/MainPageSlider";
import PropertyMainPage from "../PropertyMainPage/PropertyMainPage";


class HomePage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        return (
            <div>


                <MainPageSlider/>
                <AboutCompanyMainPage/>
                <ServicesMainPage/>
                <PropertyMainPage/>
                <ContactMainPage/>
            </div>
        )
    }
};
export default withRouter(HomePage);
