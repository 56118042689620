import React, {Component} from 'react';

import './App.css';
import './Vendor.js';

import {IntlProvider} from 'react-intl';
import {HashRouter, Link} from "react-router-dom";
import RealEstateApp from "./component/RealEstateApp/RealEstateApp";
import CookieConsent from "react-cookie-consent";
import { Helmet, HelmetProvider } from 'react-helmet-async';


class App extends Component {


    render() {

        return (
            <HelmetProvider>
                <IntlProvider
                    locale="el"
                    messages="el"
                >

                    <HashRouter>
                        <div className="App">

                            <Helmet>
                                <title>Αφοί Μιχαήλ Afoi Michail Λαμία</title>
                                <meta name="description" content="Afoi Michail Αφοί Μιχαήλ Λαμία Πολύχρονη - Εξειδικευμένη Εμπειρία στο Κατασκευαστικό Τομέα
    βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των
    προσφερομένων κατασκευαστικών υπηρεσιών από έμπειρο και εξειδικευμένο προσωπικό,
    με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των απαιτήσεων των πελατών της.
    Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας"/>

                                <meta itemProp="name" content="Afoi Michail Αφοί Μιχαήλ Λαμία"/>

                                <meta itemProp="description" content="Afoi Michail Αφοί Μιχαήλ Λαμία Πολύχρονη - Εξειδικευμένη Εμπειρία στο Κατασκευαστικό Τομέα
    βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των
    προσφερομένων κατασκευαστικών υπηρεσιών από έμπειρο και εξειδικευμένο προσωπικό,
    με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των απαιτήσεων των πελατών της.
    Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας"/>

                                <meta itemProp="image" content="https://afoimichail.com/mainSitePage.jpg"/>

                                <meta property="og:type" content="website" />
                                <meta property="og:url"
                                      content={`https://afoimichail.com/${window.location.hash}`}/>                                <meta property="og:type" content="article"/>
                                <meta property="og:title" content="Afoi Michail Αφοί Μιχαήλ Λαμία"/>
                                <meta property="og:description" content="Afoi Michail Αφοί Μιχαήλ Λαμία Πολύχρονη - Εξειδικευμένη Εμπειρία στο Κατασκευαστικό Τομέα
    βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των
    προσφερομένων κατασκευαστικών υπηρεσιών από έμπειρο και εξειδικευμένο προσωπικό,
    με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των απαιτήσεων των πελατών της.
    Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας "/>
                                <meta property="og:image"
                                      content="https://afoimichail.com/mainSitePage.jpg"/>
                                <meta property="fb:app_id"
                                      content="770753807046943"/>
                            </Helmet>

                            <RealEstateApp/>

                        </div>


                        <CookieConsent
                            location="bottom"
                            buttonText="Αποδέχομαι"
                            cookieName="myAwesomeCookieName2"
                            style={{background: "#2B373B"}}
                            buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                            expires={150}
                        >
                            Ο παρών ιστότοπος χρησιμοποιεί για τη λειτουργία του cookies.
                            Ενημερωθείτε για την πολιτική χρήσης τους &nbsp;&nbsp;

                            <Link to="/policyTerms" target="_blank" rel="noopener noreferrer"
                                  style={{textDecoration: 'none'}}>
                                πατώντας εδώ</Link>.
                            {" "}


                        </CookieConsent>
                    </HashRouter>

                </IntlProvider>
            </HelmetProvider>
        );
    }
}


export default App;

