/**
 *  Contact Detail Page
 */
import React from 'react';
import { Row, Col, Container } from 'reactstrap';



function ContactDetail() {

  return (
      <Container>
        <Row>
          <Col sm={12} className="col-sm-12">
            <div className="section-title text-center mb-2">
              <h1 className="title"><a href="https://afoimichail.com/#/contact/" rel="noopener noreferrer" > Επικοινωνήστε μαζί μας</a></h1>
              <p>άμεσα για τη καλύτερη εξυπηρέτησή σας, με το εξειδικευμένο προσωπικό μας </p>
            </div>
          </Col>
        </Row>
        <Row>

          <Col md={3} lg={3} >
            <div className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font ciyashop_info_box_2-icon-style-border ciyashop_info_box_2-icon-size-lg ciyashop_info_box_2-icon-shape-round ciyashop_info_box_2-icon_position-left mb-3 mb-sm-0">
              <div className="ciyashop_info_box_2-inner clearfix">
                <div className="ciyashop_info_box_2-icon">
                  <div className="ciyashop_info_box_2-icon-wrap">
                    <div className="ciyashop_info_box_2-icon-outer">
                      <div className="ciyashop_info_box_2-icon-inner">
                        <i className="fa fa-map-marker" /> </div>
                    </div>
                  </div>
                </div>
                <div className="ciyashop_info_box_2-content">
                  <div className="ciyashop_info_box_2-content-wrap">
                    <div className="ciyashop_info_box_2-content-inner">
                      <h6 className="ciyashop_info_box_2-title inline_hover">Περιοχή : </h6>
                      <div className="ciyashop_info_box_2-content">
                        <p>Λαμία, ΤΚ: 35100</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md={3} lg={3} >
            <div className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font ciyashop_info_box_2-icon-style-border ciyashop_info_box_2-icon-size-lg ciyashop_info_box_2-icon-shape-round ciyashop_info_box_2-icon_position-left mb-3 mb-sm-0">
              <div className="ciyashop_info_box_2-inner clearfix">
                <div className="ciyashop_info_box_2-icon">
                  <div className="ciyashop_info_box_2-icon-wrap">
                    <div className="ciyashop_info_box_2-icon-outer">
                      <div className="ciyashop_info_box_2-icon-inner">
                        <i className="fa fa-phone" /> </div>
                    </div>
                  </div>
                </div>
                <div className="ciyashop_info_box_2-content">
                  <div className="ciyashop_info_box_2-content-wrap">
                    <div className="ciyashop_info_box_2-content-inner">
                      <h6 className="ciyashop_info_box_2-title inline_hover">
                        Τηλέφωνο : </h6>
                      <div className="ciyashop_info_box_2-content">
                        <p>+3 697 778 9830<br /></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md={3} lg={3} >
            <div className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font ciyashop_info_box_2-icon-style-border ciyashop_info_box_2-icon-size-lg ciyashop_info_box_2-icon-shape-round ciyashop_info_box_2-icon_position-left pb-0">
              <div className="ciyashop_info_box_2-inner clearfix">
                <div className="ciyashop_info_box_2-icon">
                  <div className="ciyashop_info_box_2-icon-wrap">
                    <div className="ciyashop_info_box_2-icon-outer">
                      <div className="ciyashop_info_box_2-icon-inner">
                        <i className="fa fa-envelope-o" /> </div>
                    </div>
                  </div>
                </div>
                <div className="ciyashop_info_box_2-content">
                  <div className="ciyashop_info_box_2-content-wrap">
                    <div className="ciyashop_info_box_2-content-inner">
                      <h6 className="ciyashop_info_box_2-title inline_hover">Email : </h6>
                      <div className="ciyashop_info_box_2-content">
                        <p>michailafoi@gmail.com<br /></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md={3} lg={3} >
            <div className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font ciyashop_info_box_2-icon-style-border ciyashop_info_box_2-icon-size-lg ciyashop_info_box_2-icon-shape-round ciyashop_info_box_2-icon_position-left pb-0">
              <div className="ciyashop_info_box_2-inner clearfix">
                <div className="ciyashop_info_box_2-icon">
                  <div className="ciyashop_info_box_2-icon-wrap">
                    <div className="ciyashop_info_box_2-icon-outer">
                      <a href="https://www.facebook.com/afoimichail" className="share-link facebook-share"
                         target="_blank" rel="noopener noreferrer" title="afoimichail" about="https://www.facebook.com/afoimichail">
                        <i className="fa fa-facebook-official fa-2x" aria-hidden="true"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

        </Row>
      </Container>

  )

}

export default ContactDetail;

