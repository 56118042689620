/**
 *  ContactMainPage
 */
import React, {Component} from 'react';
import {Container} from 'reactstrap';
import ContactDetail from "../../widgets/ContactDetail";

class ContactMainPage extends Component {

    render() {

        return (
            <div>

                <section id="contact" className="wow fadeInUp">
                    <div className="site-content">
                        <Container>
                            <div className="content-wrapper">
                                <div className="section-wrapper section-pt pb-6">
                                    <ContactDetail/>
                                </div>

                            </div>
                        </Container>
                    </div>

                </section>

            </div>
        )
    }
};

export default ContactMainPage;
// export default GoogleApiWrapper({
//     apiKey: 'AIzaSyBfqH9LV7E0y3DvblpSUoAHkA33S7pWCII'
// })(ContactMainPage);


