/**
 *  Page Not Found Page
 */
import React, {Component} from 'react';
import PageNotFoundImage from '../../assets/images/error-404-image.jpg';
import {Link, withRouter} from 'react-router-dom';
import {Row, Col, Container} from 'reactstrap';
import {Helmet} from "react-helmet";

class PageNotFound extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div className="site-content">

                <Helmet>
                    <title>«Αφοί Μιχαήλ» </title>
                    <meta name="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα"/>

                    <meta itemProp="name" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>

                    <meta itemProp="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα"/>

                    <meta itemProp="image" content="https://afoimichail.com//mainSitePage.jpg"/>

                    <meta property="og:url"
                          content={`https://afoimichail.com/${window.location.hash}`}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>
                    <meta property="og:description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα"/>
                    <meta property="og:image"
                          content="https://afoimichail.com/mainSitePage.jpg"/>

                </Helmet>

                <div
                    className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
                    <Container>
                        <Row className="intro-title align-items-center intro-section-center">
                            <Col>
                                <div className="intro-title-inner">
                                    <h1>Πρόβλημα 404</h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="content-wrapper section-pt pb-6">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={7} className="text-center text-lg-left">
                                <div className="error404 no-results not-found">
                                    <div className="error-block clearfix">
                                        <h1 className="error-block-title">404</h1>
                                        <p className="error-block-subtitle">Oops ! Η σελίδα που ζητήσατε δε βρέθηκε.</p>
                                    </div>
                                    <div className="error-search-box">
                                        <p className="error-search-box-description">Πλοηγηθείτε στην αρχική σελίδα
                                            <Link className="error-search-box-description-link" to="/">
                                                &nbsp; Αρχική
                                            </Link>
                                        </p>

                                    </div>
                                </div>
                            </Col>
                            <Col sm={5} className="d-none d-lg-block d-xl-block">
                                <div className="content-404-image">
                                    <img className="img-fluid" src={PageNotFoundImage} alt="404"/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withRouter(PageNotFound);
