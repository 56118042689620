/**
 *  General Info Page
 */
import React, { Component } from 'react';
import {Col, Row} from 'reactstrap';
import {withRouter} from "react-router-dom";


class GeneralInfo extends Component {

    render() {

        return (
            <div
                className="product-info-box border-top border-bottom mt-5  pt-4 pt-lg-0 pb-2 pb-sm-0">
                <Row>
                    <Col sm={6} md={4}>
                        <div
                            className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font  ciyashop_info_box_2-icon-size-md  ciyashop_info_box_2-icon_position-left icon-left-spacing">
                            <div
                                className="ciyashop_info_box_2-inner clearfix align-items-center">
                                <div className="ciyashop_info_box_2-icon">
                                    <div className="ciyashop_info_box_2-icon-wrap">
                                        <div className="ciyashop_info_box_2-icon-outer">
                                            <div className="ciyashop_info_box_2-icon-inner">
                                                <br/> <br/>
                                                <i className="glyph-icon pgsicon-ecommerce-recommended fa-2x"/>
                                                <br/> <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ciyashop_info_box_2-content">
                                    <div className="ciyashop_info_box_2-content-wrap">
                                        <div className="ciyashop_info_box_2-content-inner">
                                            <h6 className="ciyashop_info_box_2-title inline_hover">Αξιοπιστία </h6>
                                            {/*<div className="ciyashop_info_box_2-content">*/}
                                            {/*    <p>Lorem Ipsum is simply dummy text of the*/}
                                            {/*        printing and typesetting industry</p>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col sm={6} md={4} className="py-3 py-md-0">
                        <div
                            className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font  ciyashop_info_box_2-icon-size-md  ciyashop_info_box_2-icon_position-left icon-left-spacing">
                            <div
                                className="ciyashop_info_box_2-inner clearfix align-items-center">
                                <div className="ciyashop_info_box_2-icon">
                                    <div className="ciyashop_info_box_2-icon-wrap">
                                        <div className="ciyashop_info_box_2-icon-outer">
                                            <div className="ciyashop_info_box_2-icon-inner">
                                                <br/> <br/>
                                                <i className="glyph-icon pgsicon-ecommerce-headphones-1 fa-2x"/>
                                                <br/> <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ciyashop_info_box_2-content">
                                    <div className="ciyashop_info_box_2-content-wrap">
                                        <div className="ciyashop_info_box_2-content-inner">
                                            <h6 className="ciyashop_info_box_2-title inline_hover">
                                                Επικοινωνία 24/7  </h6>
                                            {/*<div className="ciyashop_info_box_2-content">*/}
                                            {/*    <p>Lorem Ipsum is simply dummy text of the*/}
                                            {/*        printing and typesetting industry</p>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col sm={6} md={4}>
                        <div
                            className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font  ciyashop_info_box_2-icon-size-md  ciyashop_info_box_2-icon_position-left icon-left-spacing">
                            <div
                                className="ciyashop_info_box_2-inner clearfix align-items-center">
                                <div className="ciyashop_info_box_2-icon">
                                    <div className="ciyashop_info_box_2-icon-wrap">
                                        <div className="ciyashop_info_box_2-icon-outer">
                                            <div className="ciyashop_info_box_2-icon-inner">
                                                <br/> <br/>
                                                <i className="glyph-icon pgsicon-ecommerce-placeholder-1 fa-2x"/>
                                                <br/> <br/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ciyashop_info_box_2-content">
                                    <div className="ciyashop_info_box_2-content-wrap">
                                        <div className="ciyashop_info_box_2-content-inner">
                                            <h6 className="ciyashop_info_box_2-title inline_hover">
                                            Εύρεση Κατοικίας στη περιοχή της Λαμίας</h6>
                                            {/*<div className="ciyashop_info_box_2-content">*/}
                                            {/*    <p>Lorem Ipsum is simply dummy text of the*/}
                                            {/*        printing and typesetting industry</p>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            </div>
        )
    }
}
export default withRouter(GeneralInfo);
