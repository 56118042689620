/**
 *  Services
 */
import React, {Component} from 'react';
import {Container} from "reactstrap";
import PageTitle from "../../widgets/PageTitle";
import {withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";

class Services extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {

        return (
            <div className="site-content">
                <div
                    className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
                    <Container>
                        <div className="row intro-title align-items-center intro-section-center">

                            <Helmet>
                                <title>Υπηρεσίες «Αφοί Μιχαήλ» </title>
                                <meta name="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα.
                                      Πώληση Ακινήτων, Διαμερισμάτων, Ενοικίαση Ακινήτων στη περιοχής της λαμίας φθιώτιδας
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                                <meta itemProp="name" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>

                                <meta itemProp="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα.
                                      Πώληση Ακινήτων, Διαμερισμάτων, Ενοικίαση Ακινήτων στη περιοχής της λαμίας φθιώτιδας
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                                <meta itemProp="image" content="https://afoimichail.com//mainSitePage.jpg"/>

                                <meta property="og:url"
                                      content={`https://afoimichail.com/${window.location.hash}`}/>
                                <meta property="og:type" content="website"/>
                                <meta property="og:title" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>
                                <meta property="og:description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα.
                                      Πώληση Ακινήτων, Διαμερισμάτων, Ενοικίαση Ακινήτων στη περιοχής της λαμίας φθιώτιδας
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>
                                <meta property="og:image"
                                      content="https://afoimichail.com/mainSitePage.jpg"/>

                            </Helmet>

                            <PageTitle title="Υπηρεσίες"/>
                        </div>
                    </Container>
                </div>

                <section id="services">

                    <div className="container">

                        <div className="row">

                            <div className="col-lg-6">

                                <div className="box wow fadeInLeft">
                                    <div className="icon"><i className="fa fa-handshake-o"/></div>
                                    <h4 className="title">
                                        <a href="https://afoimichail.com/#/properties" rel="noopener noreferrer">Πώληση
                                            Ακινήτων</a>
                                    </h4>
                                    {/*<p className="description">Voluptatum deleniti atque corrupti quos dolores et quas*/}
                                    {/*    molestias excepturi sint occaecati cupiditate non provident etiro rabeta*/}
                                    {/*    lingo.</p>*/}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="box wow fadeInRight">
                                    <div className="icon"><i className="fa fa-star-o"/></div>
                                    <h4 className="title">
                                        <a href="https://afoimichail.com/#/properties" rel="noopener noreferrer">Ενοικίαση
                                            Ακινήτων</a>
                                    </h4>
                                    {/*<p className="description">Minim veniam, quis nostrud exercitation ullamco laboris*/}
                                    {/*    nisi*/}
                                    {/*    ut aliquip ex ea commodo consequat tarad limino ata nodera clas.</p>*/}
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>
        )
    }
};
export default withRouter(Services);


