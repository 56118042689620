/**
 *  About Us Page
 */
import React, {Component} from 'react';
import AboutBanner2 from '../../widgets/AboutBanner2';
import InterestingFacts from '../../widgets/InterestingFacts';
import PageTitle from '../../widgets/PageTitle';
import {Container} from 'reactstrap';
import {withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";

class Aboutus extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (

            <div className="site-content">
                <div
                    className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
                    <Container>
                        <div className="row intro-title align-items-center intro-section-center">

                            <Helmet>
                                <title>Η Εταιρία «Αφοί Μιχαήλ» </title>
                                <meta name="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                                <meta itemProp="name" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>

                                <meta itemProp="description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>

                                <meta itemProp="image" content="https://afoimichail.com//mainSitePage.jpg"/>

                                <meta property="og:url"
                                      content={`https://afoimichail.com/${window.location.hash}`}/>
                                <meta property="og:type" content="website"/>
                                <meta property="og:title" content="Η Εταιρία Afoi Michail Αφοί Μιχαήλ Λαμία"/>
                                <meta property="og:description" content="Η Η Εταιρία «Αφοί Μιχαήλ» Αφοί Μιχαήλ
                                      βασιζόμενη στην πείρα και την τεχνογνωσία που διαθέτει, έχει στόχο τη συνεχή διεύρυνση των προσφερομένων κατασκευαστικών υπηρεσιών
                                      από έμπειρο και εξειδικευμένο προσωπικό,
                                      με επίκεντρο πάντα την ποιοτική και πλήρη ικανοποίηση των αναγκών και των
                                      απαιτήσεων των πελατών της. Εξειδίκευση, Εμπειρία , Πολύχρονη και Πολύπλευρη Τεχνογνωσία στο Κατασκευαστικό Τομέα
                                       Παρέχονται ακίνητα-διαμερίσματα-διαμέρισμα για ενοικίαση - ενοικιάσεις ή πώληση πωλήσεις στη περιοχή της Λαμίας Φθιώτιδας. Η αφοι μιχαή
ασχολείται αποκλειστικά με τη διαχείριση ενοικίαση και πώληση ακινήτων διαμερισμών νεόδμητων με σύγχρονη αρχιτεκτονική"/>
                                <meta property="og:image"
                                      content="https://afoimichail.com/mainSitePage.jpg"/>

                            </Helmet>

                            <PageTitle title="Η Εταιρία"/>
                        </div>
                    </Container>
                </div>


                <div className="content-wrapper">
                    <AboutBanner2/>
                    <InterestingFacts/>


                </div>
            </div>
        )
    }
}

export default withRouter(Aboutus);
