/**
 *  MainPageSlider
 */
import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FacebookProvider, Like} from "react-facebook";



class MainPageSlider extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.Checkscroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.Checkscroll);
    }

    render() {

        return (
            <div>


                <section id="intro">

                    <div className="intro-content">
                        <h1 style={{marginRight:'35%'}}> Εύρεση</h1>
                        <h1 style={{marginRight:'15%'}}> Κατοικίας!</h1>

                        <div>
                            <Link to={`/properties`} className="btn-get-started scrollto" style={{textDecoration: 'none'}}>Πώληση - Ενοικίαση </Link>
                        </div>



                        <br/>

                        <div className="contact-info float-left">

                            <FacebookProvider appId="770753807046943">
                                <Like href={`https://afoimichail.com/#/`}
                                      colorScheme="dark"
                                      showFaces="true"
                                      layout="button_count"
                                      size="large"
                                      share="false"
                                      referral="https://www.facebook.com/afoimichail/"
                                />
                            </FacebookProvider>
                            <h6>&nbsp;&nbsp;  <br/>  <br/>
                            <i className="fa fa-phone"/> <b style={{color:'black'}} >+3 697 778 9830</b></h6>
                        </div>
                    </div>

                </section>


            </div>
        )
    }
};
export default MainPageSlider;


