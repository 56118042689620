/**
 *  Footer Main
 */
import React, {Component} from 'react';
import {Row, Col, Container} from 'reactstrap';
import {Link} from 'react-router-dom';
import FooterFacebookRight from "./FooterFacebookRight/FooterFacebookRight";

class Footer extends Component {


    componentDidMount() {
        window.addEventListener('scroll', this.Checkscroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.Checkscroll);
    }

    Checkscroll() {

        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;

        if (scrollTop > 350) {
            if (document.getElementById("back-to-top") != null) {
                document.getElementById("back-to-top").setAttribute("style", "display:block");
            }
        } else {

            if (document.getElementById("back-to-top") != null) {
                document.getElementById("back-to-top").setAttribute("style", "display:none");
            }
        }

    }

    ClicktoTop() {
        window.scroll({top: 0, left: 0, behavior: 'smooth'})
    }

    render() {
        let backtotop = {display: 'none'}
        return (
            <div>

                <footer className="site-footer">
                    <div className="footer-wrapper">
                        <div className="footer-widgets-wrapper">
                            <div className="footer">
                                <Container>
                                    <Row>
                                        <div className="col-lg-3 col-md-6 footer-align-left">
                                            <div className="logo-wrapper widget">
                                                <h1><a href="https://afoimichail.com" >
                                                    <img className="img-fluid"
                                                         src={require(`../../assets/images/logo.jpg`)} alt="logo"/>
                                                </a></h1>
                                            </div>
                                            <div className="text-content">
                                                <p className="mb-3 mt-4" style={{textAlign: 'justify', margin:'15px'}}>Η «Αφοί Μιχαήλ» με την αξιοποίηση της τεχνογνωσίας στο  κατασκευαστικό τομέα παρέχει σύγχρονα ακίνητα που εναρμονίζονται
                                                    με τον σύγχρονο τρόπο ζωής διαμορφώνοντας το άριστο περιβάλλον διαβίωσης. </p>
                                                <p className="mb-4" style={{textAlign: 'justify', margin:'15px'}}>Με επίκεντρο τη ποιοτική ικανοποίηση
                                                    των πελατών ανταποκρίνεται στις απαιτήσεις της αγοράς ακινήτων </p>
                                            </div>


                                            <div className="pgs-social-profiles mt-4">
                                                <div className="social-profiles-wrapper">
                                                    <div
                                                        className="social-profiles-wrapper-inner social-profiles-default social-profiles-shape-square">
                                                        <div className="social-profiles">
                                                            <a href="https://www.facebook.com/afoimichail"
                                                               className="share-link facebook-share"
                                                               target="_blank" rel="noopener noreferrer"
                                                               title="afoimichail"
                                                               about="https://www.facebook.com/afoimichail">
                                                                <i className="fa fa-facebook-official fa-4x"
                                                                   aria-hidden="true"/>
                                                            </a>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 footer-align-left">
                                            <div className="footer-nav-menu widget">
                                                <h4 className="footer-title title">Χρήσιμοι Σύνδεσμοι</h4>
                                                <div className="menu-useful-links-container">
                                                    <ul className="menu">
                                                        <li className="menu-item active">
                                                            <a href="https://afoimichail.com/" style={{textDecoration: 'none'}}> ΑΡΧΙΚΗ</a>
                                                        </li>
                                                        <li className="menu-item"><Link to="/about"
                                                                                        style={{textDecoration: 'none'}}>Η
                                                            ΕΤΑΙΡΙΑ</Link></li>
                                                        <li className="menu-item"><Link to="/contact"
                                                                                        style={{textDecoration: 'none'}}>ΕΠΙΚΟΙΝΩΝΙΑ</Link>
                                                        </li>

                                                        <li className="menu-item">
                                                            <a href="https://www.facebook.com/afoimichail" target="_blank"
                                                               rel="noopener noreferrer"
                                                               style={{textDecoration: 'none'}}>FACEBOOK</a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-6 footer-align-left">
                                            <div className="pgs-contact-widget widget mt-4 mt-lg-0">
                                                <h4 className="footer-title title">Πληροφορίες</h4>
                                                <div className="menu-useful-links-container">
                                                    <ul className="menu">
                                                        <li className="menu-item">
                                                            <Link to="/policyTerms" style={{textDecoration: 'none'}}>
                                                                Όροι χρήσης – Πολιτική Προστασίας Προσωπικών Δεδομένων</Link></li>

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-lg-3 col-md-6 footer-align-left">
                                            <div className="pgs-contact-widget widget mt-4 mt-lg-0">
                                                <h4 className="footer-title title">Επικοινωνία</h4>
                                                <div className="footer-address">
                                                    <ul>
                                                        <li><i className="fa fa-map-marker"/><span>Λαμία</span></li>
                                                        <li className="pgs-contact-email"><i
                                                            className="fa fa-envelope-o"/><span>michailafoi@gmail.com</span>
                                                        </li>
                                                        <li><i className="fa fa-phone"/><span>+3 697 778 9830</span>
                                                        </li>

                                                    </ul>
                                                    <FooterFacebookRight />
                                                </div>
                                            </div>

                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div>

                        <div className="site-info">
                            <div className="footer-widget">
                                <Container>
                                    <div className="row align-items-center">
                                        <Col md={6} className="float-left">
                                            <p> © Copyright 2020 <Link to="/"><strong>Αφοι Μιχαήλ</strong></Link> All
                                                Rights Reserved.</p>
                                        </Col>
                                        <Col md={6} className="float-right">
                                            <div className="credits">

                                                Ανάπτυξη

                                                <a href="https://co-athanasiou.gr" target="_blank"
                                                   rel="noopener noreferrer"
                                                   style={{textDecoration: 'none'}}> Αθανασίου Κωνσταντίνος, Μηχανικός
                                                    Λογισμικού (Software Engineer)</a>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className="clearfix"/>
                                </Container>
                            </div>
                        </div>
                    </div>
                </footer>

                <div id="back-to-top" style={backtotop} onClick={this.ClicktoTop}>
                    <Link class="top arrow">
                        <i class="fa fa-angle-up"></i>
                    </Link>
                </div>

            </div>
        )
    }
};
export default Footer;


